<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>
        <template v-slot:right>
          <Button
            icon="pi pi-plus"
            class="p-mr-2 p-button-success"
            @click="openNewStatus"
          />
          <Button
            icon="pi pi-search"
            class="p-mr-2 p-button-primary"
            @click="openSearchForm"
          />
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="registerList"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <Column header="Employee" headerStyle="width: 200px">
            <template #body="slotProps">
              {{ slotProps.data.employeeFirstname }}
              {{ slotProps.data.employeeName }} ({{
                slotProps.data.employeeId
              }})
            </template>
          </Column>
          <Column header="Opening Date/Time" headerStyle="width: 200px">
            <template #body="slotProps">
              {{ formatDate(slotProps.data.date) }} /
              {{ formatTime(slotProps.data.time) }}
            </template>
          </Column>
          <Column header="Cash Opening Balance" headerStyle="width: 180px">
            <template #body="slotProps">
              <b>$ {{ formatAmount(slotProps.data.totalBalance) }}</b>
            </template>
          </Column>
          <Column header="Closing Date/Time" headerStyle="width: 180px">
            <template #body="slotProps">
              <span
                v-if="
                  slotProps.data.closingDate != '' &&
                  slotProps.data.closingTime != ''
                "
              >
                {{ formatDate(slotProps.data.closingDate) }} /
                {{ formatTime(slotProps.data.closingTime) }}</span
              >
            </template>
          </Column>
          <Column header="Cash Closing Balance" headerStyle="width: 180px"
            >>
            <template #body="slotProps">
              <b> $ {{ formatAmount(slotProps.data.closingBalance) }} </b>
            </template>
          </Column>
          <Column header="Created Store" headerStyle="width: 300px">
            <template #body="slotProps">
              {{ slotProps.data.mall }} | {{ slotProps.data.isAdmin }}
            </template>
          </Column>
          <Column header="Mode" headerStyle="width: 100px">
            <template #body="slotProps">
              <Button
                disabled="disabled"
                icon="pi pi-lock"
                v-if="slotProps.data.statusType == 'close'"
                :label="slotProps.data.statusType.toUpperCase()"
                style="width: 80px"
                class="p-button-success p-p-1"
              />
              <Button
                disabled="disabled"
                icon="pi pi-unlock"
                v-if="slotProps.data.statusType == 'open'"
                :label="slotProps.data.statusType.toUpperCase()"
                style="width: 80px"
                class="p-button-danger p-p-1"
              />
            </template>
          </Column>
          <Column
            :exportable="false"
            headerStyle="width: 300px"
            header="Action"
          >
            <template #body="slotProps" class="p-mt-2 p-mb-2">
              <span class="p-buttonset">
                <Button
                  class="p-button-warning p-button-rounded"
                  @click="openDayStatus(slotProps.data.registerId)"
                  label="Open Day"
                />
                <Button
                  class="p-button-primary"
                  @click="closeDayStatus(slotProps.data.registerId)"
                  label="Close Day"
                />
                <Button
                  class="p-button-danger p-button-rounded"
                  @click="openReconciliation(slotProps.data.registerId)"
                  label="Options"
                />
              </span>
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-model:visible="openDialog"
          :style="{ width: '100vw', height: '100vh' }"
          :maximizable="false"
          :closable="false"
          position="top"
          class="p-fluid"
        >
          <template #header>
            <div class="row" style="width: 100vw">
              <div class="col-md-4">
                <h6 class="p-dialog-titlebar p-dialog-titlebar-icon p-mt-2">
                  <i :class="iconName"></i> {{ dialogTitle }}
                </h6>
              </div>
              <div class="col-md-8">
                <span class="p-buttonset">
                  <Button
                    class="p-button-success p-ml-1"
                    icon="pi pi-unlock"
                    @click="countDrawerDialog = true"
                    label="Count Drawer"
                  />
                  <Button
                    v-if="dialogStatus == 'new'"
                    class="p-button-primary p-ml-1"
                    icon="pi pi-check-circle"
                    @click="acceptDrawer()"
                    label="Accept Drawer"
                  />
                  <Button
                    v-if="dialogStatus == 'open_day'"
                    class="p-button-primary p-ml-1"
                    icon="pi pi-pencil"
                    @click="updateDayDrawer()"
                    label="Update & Accept Drawer"
                  />
                  <Button
                    v-if="dialogStatus == 'close_day'"
                    class="p-button-primary p-ml-1"
                    icon="pi pi-pencil"
                    @click="updateCloseDay()"
                    label="Update & Accept Drawer"
                  />
                  <Button
                    class="p-button-danger p-ml-1"
                    icon="pi pi-times"
                    @click="closeDrawerScreen()"
                    label="Close"
                  />
                </span>
              </div>
            </div>
          </template>
          <div class="row">
            <div class="col-md-6">
              <table class="table table-bordered table-striped cash-register">
                <thead>
                  <tr>
                    <th>Counts</th>
                    <th>Enter Counts</th>
                    <th></th>
                    <th>Total Counts</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="p-pt-2"><b>100's</b></td>
                    <td>
                      <InputNumber
                        autoFocus
                        v-model="multipliers.totalHundreds"
                        :useGrouping="false"
                      />
                    </td>
                    <td class="p-pt-2"><b>=</b></td>
                    <td>
                      <InputNumber
                        disabled="disabled"
                        :value="totalCashDrawer.totalHundreds"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>50's</b></td>
                    <td>
                      <InputNumber
                        v-model="multipliers.totalFifties"
                        :useGrouping="false"
                      />
                    </td>
                    <td class="p-pt-2"><b>=</b></td>
                    <td>
                      <InputNumber
                        disabled="disabled"
                        :value="totalCashDrawer.totalFifties"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>20's</b></td>
                    <td>
                      <InputNumber
                        v-model="multipliers.totalTwenties"
                        :useGrouping="false"
                      />
                    </td>
                    <td class="p-pt-2"><b>=</b></td>
                    <td>
                      <InputNumber
                        disabled="disabled"
                        :value="totalCashDrawer.totalTwenties"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>10's</b></td>
                    <td>
                      <InputNumber
                        v-model="multipliers.totalTens"
                        :useGrouping="false"
                      />
                    </td>
                    <td class="p-pt-2"><b>=</b></td>
                    <td>
                      <InputNumber
                        disabled="disabled"
                        :value="totalCashDrawer.totalTens"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>5's</b></td>
                    <td>
                      <InputNumber
                        v-model="multipliers.totalFives"
                        :useGrouping="false"
                      />
                    </td>
                    <td class="p-pt-2"><b>=</b></td>
                    <td>
                      <InputNumber
                        disabled="disabled"
                        :value="totalCashDrawer.totalFives"
                      />
                    </td>
                  </tr>
                  <tr class="register-total">
                    <td>TOTAL BALANCE</td>
                    <td colspan="3" style="text-align: right">
                      <b>${{ sumDrawerCash }}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-6">
              <table class="table table-bordered table-striped cash-register">
                <thead>
                  <tr>
                    <th>Counts</th>
                    <th>Enter Counts</th>
                    <th></th>
                    <th>Total Counts</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="p-pt-2"><b>2.00</b></td>
                    <td>
                      <InputNumber
                        v-model="multipliers.totalCoinsTwos"
                        :useGrouping="false"
                      />
                    </td>
                    <td class="p-pt-2"><b>=</b></td>
                    <td>
                      <InputNumber
                        disabled="disabled"
                        :value="totalCashDrawer.totalCoinsTwos"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>1.00</b></td>
                    <td>
                      <InputNumber
                        v-model="multipliers.totalCoinsOnes"
                        :useGrouping="false"
                      />
                    </td>
                    <td class="p-pt-2"><b>=</b></td>
                    <td>
                      <InputNumber
                        disabled="disabled"
                        :value="totalCashDrawer.totalCoinsOnes"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>0.25</b></td>
                    <td>
                      <InputNumber
                        v-model="multipliers.totalCoinsTwoFive"
                        :useGrouping="false"
                      />
                    </td>
                    <td class="p-pt-2"><b>=</b></td>
                    <td>
                      <InputNumber
                        disabled="disabled"
                        :value="totalCashDrawer.totalCoinsTwoFive"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>0.10</b></td>
                    <td>
                      <InputNumber
                        v-model="multipliers.totalCoinsOneZero"
                        :useGrouping="false"
                      />
                    </td>
                    <td class="p-pt-2"><b>=</b></td>
                    <td>
                      <InputNumber
                        disabled="disabled"
                        :value="totalCashDrawer.totalCoinsOneZero"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>0.05</b></td>
                    <td>
                      <InputNumber
                        v-model="multipliers.totalCoinsZeroFive"
                        :useGrouping="false"
                      />
                    </td>
                    <td class="p-pt-2"><b>=</b></td>
                    <td>
                      <InputNumber
                        disabled="disabled"
                        :value="totalCashDrawer.totalCoinsZeroFive"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Dialog>
        <Dialog
          v-model:visible="openRecon"
          :style="{ width: '100vw', height: '100vh' }"
          :maximizable="false"
          :closable="false"
          position="top"
          class="p-fluid"
        >
          <template #header>
            <div class="row" style="width: 100vw">
              <div class="col-md-12">
                <h6 class="p-dialog-titlebar p-dialog-titlebar-icon p-mt-2">
                  <i class="pi pi-plus-circle"></i> End of Day Cash Drawer
                  Reconciliation
                </h6>
              </div>
            </div>
          </template>
          <div class="row">
            <div class="col-md-9">
              <table class="table table-bordered cash-register">
                <thead>
                  <tr>
                    <th></th>
                    <th>STATED</th>
                    <th>ACTUAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="p-pt-2"><b>Starting Balance</b></td>
                    <td>
                      <InputNumber
                        :value="
                          registerReconList.statedStartingBalance.toFixed(2)
                        "
                        :useGrouping="false"
                        disabled="disabled"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>Cash</b></td>
                    <td>
                      <InputNumber
                        name="cashStated"
                        :value="registerReconList.statedCash.toFixed(2)"
                        :useGrouping="false"
                        disabled="disabled"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="cashActual"
                        v-model="registerReconList.actualCash"
                        :useGrouping="false"
                        disabled="disabled"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>Tips Collected</b></td>
                    <td>
                      <InputNumber
                        :value="
                          registerReconList.statedTipsCollected.toFixed(2)
                        "
                        :useGrouping="false"
                        disabled="disabled"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                    <td>
                      <InputNumber
                        v-model="registerReconList.actualTipsCollected"
                        autoFocus
                        :useGrouping="false"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>Visa</b></td>
                    <td>
                      <InputNumber
                        :value="registerReconList.statedVisa.toFixed(2)"
                        :useGrouping="false"
                        disabled="disabled"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                    <td>
                      <InputNumber
                        v-model="registerReconList.actualVisa"
                        :useGrouping="false"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>Master Card</b></td>
                    <td>
                      <InputNumber
                        :value="registerReconList.statedMasterCard.toFixed(2)"
                        :useGrouping="false"
                        disabled="disabled"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                    <td>
                      <InputNumber
                        v-model="registerReconList.actualMasterCard"
                        :useGrouping="false"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>AMEX</b></td>
                    <td>
                      <InputNumber
                        :value="registerReconList.statedAMEX.toFixed(2)"
                        :useGrouping="false"
                        disabled="disabled"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                    <td>
                      <InputNumber
                        v-model="registerReconList.actualAMEX"
                        :useGrouping="false"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>Discover</b></td>
                    <td>
                      <InputNumber
                        readonly
                        :value="registerReconList.statedDiscover.toFixed(2)"
                        :useGrouping="false"
                        disabled="disabled"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                    <td>
                      <InputNumber
                        v-model="registerReconList.actualDiscover"
                        :useGrouping="false"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>Gift Card</b></td>
                    <td>
                      <InputNumber
                        readonly
                        :value="registerReconList.statedGiftCard.toFixed(2)"
                        :useGrouping="false"
                        disabled="disabled"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                    <td>
                      <InputNumber
                        v-model="registerReconList.actualGiftCard"
                        :useGrouping="false"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="p-pt-2"><b>Debit Card</b></td>
                    <td>
                      <InputNumber
                        readonly
                        :value="registerReconList.statedDebitCard.toFixed(2)"
                        :useGrouping="false"
                        disabled="disabled"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                    <td>
                      <InputNumber
                        v-model="registerReconList.actualDebitCard"
                        :useGrouping="false"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                  </tr>
                  <tr class="register-total">
                    <td class="p-pt-2"><b>ENDING BALANCE</b></td>
                    <td class="text-left">
                      <b> $ {{ sumStatedAmount }} </b>
                    </td>
                    <td class="text-left">
                      <b> $ {{ sumActualAmount }} </b>
                    </td>
                  </tr>
                  <tr class="register-total">
                    <td class="p-pt-2"><b>DIFFERENCE</b></td>
                    <td class="text-left">
                      <b> $ {{ getDifference }} </b>
                    </td>
                    <td class="text-left"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-3">
              <Button
                style="background-color: #353434"
                class="p-button-primary p-p-3"
                icon="pi pi-home"
                @click="redirectHome()"
                label="Dashboard"
              />
              <template v-if="statusType != 'close'">
                <Button
                  class="p-button-primary p-p-3 p-mt-2"
                  icon="pi pi-file-o"
                  @click="countDrawerDialog = true"
                  label="Count Drawer"
                />
                <Button
                  class="p-button-success p-p-3 p-mt-2"
                  icon="pi pi-pencil"
                  @click="savePaymentDetails()"
                  label="Save Details"
                />
                <Button
                  class="p-button-danger p-p-3 p-mt-2"
                  icon="pi pi-lock"
                  @click="submitDialog = true"
                  label="Submit Drawer"
                />
              </template>
              <Button
                class="p-button-primary p-p-3 p-mt-2"
                icon="pi pi-print"
                @click="updateDayDrawer()"
                label="Print"
              />
              <Button
                class="p-button-primary p-p-3 p-mt-2"
                icon="pi pi-times"
                @click="closeReconScreen()"
                label="Exit"
              />
              <div class="form-group p-mt-2">
                <label for="Description">Notes</label>
                <textarea
                  id="Description"
                  cols="40"
                  rows="5"
                  class="p-p-2"
                  v-model="registerReconList.notes"
                  placeholder="Write any note ..."
                  editorStyle="height: 320px"
                ></textarea>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  </section>
  <Dialog
    v-model:visible="countDrawerDialog"
    :style="{ width: '30vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid"
  >
    <template #header>
      <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">Open Cash Drawer</h4>
    </template>
    <div class="p-field">
      <label for="name">Associate ID</label>
      <input
        type="password"
        id="name"
        v-model.trim="countDrawer.associateID"
        required="true"
        autofocus
        class="form-control"
        :class="{ 'p-invalid': submitted && !countDrawer.associateID }"
      />
      <small class="p-invalid" v-if="submitted && !countDrawer.associateID"
        >Associate PIN is requred.</small
      >
    </div>
    <div class="p-field">
      <label for="Priority">Opening Reason</label>
      <InputText
        id="Priority"
        v-model="countDrawer.reason"
        :class="{ 'p-invalid': submitted && !countDrawer.reason }"
      />
      <small class="p-invalid" v-if="submitted && !countDrawer.reason"
        >Reason is requred.</small
      >
    </div>
    <template #footer>
      <Button
        type="submit"
        label="Open Drawer"
        icon="pi pi-check"
        class="p-button-primary"
        @click="openCountDrawer()"
      />
    </template>
  </Dialog>

  <Dialog
    v-model:visible="submitDialog"
    :style="{ width: '450px' }"
    header="Confirm"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span
        >Are you sure to submit the cash register.Once you submited only Store
        Manager can Reopen it?
      </span>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="submitDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-success"
        @click="submitCashRegister"
      />
    </template>
  </Dialog>
  <Dialog
    v-model:visible="searchDialog"
    :style="{ width: '50vw' }"
    :maximizable="true"
    position="top"
    class="p-fluid"
  >
    <template #header>
      <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
        Search Cash Register
      </h4>
    </template>
    <div class="p-field">
      <label for="name">Date From:</label>
      <input
        type="date"
        v-model.trim="searchForm.date1"
        autofocus
        class="form-control"
      />
    </div>
    <div class="p-field">
      <label for="name">Date To:</label>
      <input
        type="date"
        v-model.trim="searchForm.date2"
        autofocus
        class="form-control"
      />
    </div>
    <div class="p-field">
      <label for="storesList">Filter Stores</label>
      <MultiSelect
        class="multiselect-custom"
        v-model="searchForm.assignedBranchId"
        emptyFilterMessage="No Store found"
        :options="myStores"
        scrollHeight="300px"
        placeholder="Select Stores"
      >
        <template #value="slotProps">
          <div
            class="country-item country-item-value"
            v-for="option of slotProps.value"
            :key="option.code"
          >
            <div>Store {{ option.mall }} | Code {{ option.code }}</div>
          </div>
          <template v-if="!slotProps.value || slotProps.value.length === 0">
            Select Stores
          </template>
        </template>
        <template #option="slotProps">
          <div class="country-item">
            <div>
              Store {{ slotProps.option.mall }} | Code
              {{ slotProps.option.code }}
            </div>
          </div>
        </template>
      </MultiSelect>
    </div>
    <template #footer>
      <Button
        type="submit"
        label="Search"
        icon="pi pi-search"
        class="p-button-primary"
        @click="loadList()"
      />
    </template>
  </Dialog>

  <AssociatePinVerification
    :receiptDetail="{ associateType: verificationType }"
    v-on:emitAssociatePinEvent="closePINDialog"
  />
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import RegisterService from "../../service/RegisterService";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { camelCase } from "lodash";
import router from "../../router";
import { branchDetail } from "../../pages/announcements/IAnnounce";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";

@Options({
  components: { AssociatePinVerification },
})
export default class CashRegister extends Vue {
  private verificationType = "";
  private associateID = "";
  private lists = [];
  private dialogTitle = "Enter Beginning Cash Drawer Total";
  private registerService;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [{ label: "Cash Register" }];
  private toast;
  private iconName = "pi pi-plus-circle";
  private countDrawerDialog = false;
  private openDialog = false;
  private submitDialog = false;
  private statusType = "close";
  private submitted = false;
  private openRecon = false;
  private searchDialog = false;
  private dialogStatus = "new";
  private myStores: Array<branchDetail> = [];
  private registerId = "";
  private registerList = [
    {
      employeeFirstname: "",
      employeeName: "",
      employeeId: "",
      date: "",
      time: "",
      totalBalance: 0,
      closingDate: "",
      closingTime: "",
      closingBalance: 0,
      mall: "",
      code: "",
      isAdmin: "",
      registerId: "",
    },
  ];

  private registerReconList = {
    statedStartingBalance: 0,
    actualStartingBalance: 0,
    statedCash: 0,
    actualCash: 0,
    statedTipsCollected: 0,
    actualTipsCollected: 0,
    statedVisa: 0,
    actualVisa: 0,
    statedMasterCard: 0,
    actualMasterCard: 0,
    statedAMEX: 0,
    actualAMEX: 0,
    statedDiscover: 0,
    actualDiscover: 0,
    statedGiftCard: 0,
    actualGiftCard: 0,
    statedDebitCard: 0,
    actualDebitCard: 0,
    notes: "",
  };

  private totalCash = {
    totalHundreds: 100,
    totalFifties: 50,
    totalTwenties: 20,
    totalTens: 10,
    totalFives: 5,
    totalCoinsTwos: 2,
    totalCoinsOnes: 1,
    totalCoinsTwoFive: 0.25,
    totalCoinsOneZero: 0.1,
    totalCoinsZeroFive: 0.05,
  };

  private multipliers = {
    totalHundreds: 0,
    totalFifties: 0,
    totalTwenties: 0,
    totalTens: 0,
    totalFives: 0,
    totalCoinsTwos: 0,
    totalCoinsOnes: 0,
    totalCoinsTwoFive: 0,
    totalCoinsOneZero: 0,
    totalCoinsZeroFive: 0,
  };

  private totalRegisterCash = {
    totalHundreds: 0,
    totalFifties: 0,
    totalTwenties: 0,
    totalTens: 0,
    totalFives: 0,
    totalCoinsTwos: 0,
    totalCoinsOnes: 0,
    totalCoinsTwoFive: 0,
    totalCoinsOneZero: 0,
    totalCoinsZeroFive: 0,
    totalAmonut: 0,
  };

  private countDrawer = {
    associateID: "",
    reason: "",
  };

  private searchForm = {
    date1: "",
    date2: "",
    assignedBranchId: [],
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.registerService = new RegisterService();
    this.toast = new Toaster();
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.verificationType = "associate";
    this.loadList();
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.searchDialog = false;
    this.registerService.getRegisters(this.searchForm).then((data) => {
      const registerList = this.camelizeKeys(data);
      this.registerList = registerList;
    });
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }

  formatAmount(value) {
    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  get totalCashDrawer() {
    this.totalRegisterCash.totalHundreds = this.formatAmount(
      this.multipliers.totalHundreds * this.totalCash.totalHundreds
    );
    this.totalRegisterCash.totalFifties = this.formatAmount(
      this.multipliers.totalFifties * this.totalCash.totalFifties
    );
    this.totalRegisterCash.totalTwenties = this.formatAmount(
      this.multipliers.totalTwenties * this.totalCash.totalTwenties
    );
    this.totalRegisterCash.totalTens = this.formatAmount(
      this.multipliers.totalTens * this.totalCash.totalTens
    );
    this.totalRegisterCash.totalFives = this.formatAmount(
      this.multipliers.totalFives * this.totalCash.totalFives
    );
    this.totalRegisterCash.totalCoinsTwos = this.formatAmount(
      this.multipliers.totalCoinsTwos * this.totalCash.totalCoinsTwos
    );
    this.totalRegisterCash.totalCoinsOnes = this.formatAmount(
      this.multipliers.totalCoinsOnes * this.totalCash.totalCoinsOnes
    );
    this.totalRegisterCash.totalCoinsTwoFive = this.formatAmount(
      this.multipliers.totalCoinsTwoFive * this.totalCash.totalCoinsTwoFive
    );
    this.totalRegisterCash.totalCoinsOneZero = this.formatAmount(
      this.multipliers.totalCoinsOneZero * this.totalCash.totalCoinsOneZero
    );
    this.totalRegisterCash.totalCoinsZeroFive = this.formatAmount(
      this.multipliers.totalCoinsZeroFive * this.totalCash.totalCoinsZeroFive
    );

    return this.totalRegisterCash;
  }

  get sumDrawerCash() {
    let totalAmonut = 0;

    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalHundreds);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalFifties);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalTwenties);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalTens);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalFives);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalCoinsTwos);
    totalAmonut = totalAmonut + Number(this.totalRegisterCash.totalCoinsOnes);
    totalAmonut =
      totalAmonut + Number(this.totalRegisterCash.totalCoinsTwoFive);
    totalAmonut =
      totalAmonut + Number(this.totalRegisterCash.totalCoinsOneZero);
    totalAmonut =
      totalAmonut + Number(this.totalRegisterCash.totalCoinsZeroFive);

    this.totalRegisterCash.totalAmonut = totalAmonut;

    return totalAmonut.toFixed(2);
  }

  acceptDrawer() {
    this.registerService
      .acceptDawer(
        this.multipliers,
        this.associateID,
        this.totalRegisterCash.totalAmonut
      )
      .then((res) => {
        this.openDialog = false;
        this.toast.handleResponse(res);
        this.loadList();
      });
  }

  openNewStatus() {
    this.clearMultipiers();
    this.dialogTitle = "Enter Beginning Cash Drawer Total";
    this.dialogStatus = "new";
    this.openDialog = true;
  }

  openDayStatus(registerID) {
    this.clearMultipiers();

    this.dialogTitle = "Update Beginning Cash Drawer Total";
    this.dialogStatus = "open_day";
    this.registerId = registerID;
    this.openDialog = true;

    this.registerService.getOpenDawer(registerID).then((res) => {
      const openDay = this.camelizeKeys(res.cash_register_open);
      this.multipliers.totalHundreds = openDay.hundreds;
      this.multipliers.totalFifties = openDay.fifties;
      this.multipliers.totalTwenties = openDay.twenty;
      this.multipliers.totalTens = openDay.ten;
      this.multipliers.totalFives = openDay.five;
      this.multipliers.totalCoinsTwos = openDay.ctwo;
      this.multipliers.totalCoinsOnes = openDay.cone;
      this.multipliers.totalCoinsTwoFive = openDay.czerotwofive;
      this.multipliers.totalCoinsOneZero = openDay.conezero;
      this.multipliers.totalCoinsZeroFive = openDay.czerozerofive;
    });
  }

  closeDayStatus(registerID) {
    this.clearMultipiers();
    this.dialogTitle = "End of the Day Cash Drawer Reconciliation";
    this.dialogStatus = "close_day";
    this.openDialog = true;
    this.registerId = registerID;

    this.registerService.getOpenDawer(registerID).then((res) => {
      const closeDay = this.camelizeKeys(res.cash_register_end);
      this.multipliers.totalHundreds = closeDay.hundreds;
      this.multipliers.totalFifties = closeDay.fifties;
      this.multipliers.totalTwenties = closeDay.twenty;
      this.multipliers.totalTens = closeDay.ten;
      this.multipliers.totalFives = closeDay.five;
      this.multipliers.totalCoinsTwos = closeDay.ctwo;
      this.multipliers.totalCoinsOnes = closeDay.cone;
      this.multipliers.totalCoinsTwoFive = closeDay.czerotwofive;
      this.multipliers.totalCoinsOneZero = closeDay.conezero;
      this.multipliers.totalCoinsZeroFive = closeDay.czerozerofive;
    });
  }

  updateDayDrawer() {
    this.registerService
      .updateOpenDay(
        this.multipliers,
        this.associateID,
        this.totalRegisterCash.totalAmonut,
        this.registerId
      )
      .then((res) => {
        this.openDialog = false;
        this.toast.handleResponse(res);
        this.loadList();
      });
  }

  updateCloseDay() {
    this.registerService
      .updateCloseDay(
        this.multipliers,
        this.associateID,
        this.totalRegisterCash.totalAmonut,
        this.registerId
      )
      .then((res) => {
        this.openDialog = false;
        this.toast.handleResponse(res);
        this.loadList();
      });
  }

  closeDrawerScreen() {
    this.openDialog = false;
  }

  clearMultipiers() {
    this.multipliers = {
      totalHundreds: 0,
      totalFifties: 0,
      totalTwenties: 0,
      totalTens: 0,
      totalFives: 0,
      totalCoinsTwos: 0,
      totalCoinsOnes: 0,
      totalCoinsTwoFive: 0,
      totalCoinsOneZero: 0,
      totalCoinsZeroFive: 0,
    };

    this.totalRegisterCash.totalAmonut = 0;
  }

  openReconciliation(registerID) {
    this.openRecon = true;
    this.dialogStatus = "cash_summary";
    this.registerId = registerID;

    this.registerService.getReconPaymentList(this.registerId).then((data) => {
      const res = this.camelizeKeys(data);
      this.registerReconList.statedStartingBalance = res.openingBalance;
      this.registerReconList.statedCash = res.statedCash;
      this.registerReconList.actualCash = res.closingBalance;
      this.registerReconList.statedTipsCollected = res.statedTips;
      this.registerReconList.statedVisa = res.statedVisa;
      this.registerReconList.statedMasterCard = res.statedMaster;
      this.registerReconList.statedAMEX = res.statedAmex;
      this.registerReconList.statedDiscover = res.statedDiscover;
      this.registerReconList.statedGiftCard = res.statedGiftcard;
      this.registerReconList.statedDebitCard = res.statedDebit;

      // this.registerReconList.actualCash = res.paymentSummery.cash;
      this.registerReconList.actualTipsCollected = res.paymentSummery.tip;
      this.registerReconList.actualVisa = res.paymentSummery.visa;
      this.registerReconList.actualMasterCard = res.paymentSummery.master;
      this.registerReconList.actualAMEX = res.paymentSummery.amex;
      this.registerReconList.actualDiscover = res.paymentSummery.discover;
      this.registerReconList.actualGiftCard = res.paymentSummery.gift;
      this.registerReconList.actualDebitCard = res.paymentSummery.debit;
      this.registerReconList.notes = res.paymentSummery.notes;

      this.statusType = res.statusType;
    });
  }

  get sumStatedAmount() {
    let amount = 0;

    amount = amount + Number(this.registerReconList.statedStartingBalance);
    amount = amount + Number(this.registerReconList.statedCash);
    amount = amount + Number(this.registerReconList.statedTipsCollected);
    amount = amount + Number(this.registerReconList.statedVisa);
    amount = amount + Number(this.registerReconList.statedMasterCard);
    amount = amount + Number(this.registerReconList.statedAMEX);
    amount = amount + Number(this.registerReconList.statedDiscover);
    amount = amount + Number(this.registerReconList.statedGiftCard);
    amount = amount + Number(this.registerReconList.statedDebitCard);

    return amount.toFixed(2);
  }

  get sumActualAmount() {
    let amount = 0;

    amount = amount + Number(this.registerReconList.actualStartingBalance);
    amount = amount + Number(this.registerReconList.actualCash);
    amount = amount + Number(this.registerReconList.actualTipsCollected);
    amount = amount + Number(this.registerReconList.actualVisa);
    amount = amount + Number(this.registerReconList.actualMasterCard);
    amount = amount + Number(this.registerReconList.actualAMEX);
    amount = amount + Number(this.registerReconList.actualDiscover);
    amount = amount + Number(this.registerReconList.actualGiftCard);
    amount = amount + Number(this.registerReconList.actualDebitCard);

    return amount.toFixed(2);
  }

  get getDifference() {
    const diff = Number(this.sumActualAmount) - Number(this.sumStatedAmount);
    return diff.toFixed(2);
  }

  closeReconScreen() {
    this.openRecon = false;
  }

  redirectHome() {
    router.push({ path: "/store/dashboard", params: {} });
  }

  savePaymentDetails() {
    //
    this.registerService
      .savePaymentDetails(
        this.registerId,
        this.associateID,
        this.registerReconList,
        this.sumActualAmount,
        this.getDifference
      )
      .then((res) => {
        this.openRecon = false;
        this.toast.handleResponse(res);
        this.loadList();
      });
  }

  openCountDrawer() {
    let source = "";

    if (this.dialogStatus == "new") {
      source = "open_cash_register";
    } else if (this.dialogStatus == "open_day") {
      source = "open_day_cash_register";
    } else if (this.dialogStatus == "close_day") {
      source = "close_day_cash_register";
    } else if (this.dialogStatus == "cash_summary") {
      source = "cash_summary";
    }

    this.submitted = true;

    if (this.countDrawer.associateID != "" && this.countDrawer.reason != "") {
      this.registerService
        .openCashDrawer(this.countDrawer, source)
        .then((res) => {
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
          this.countDrawerDialog = false;
          this.countDrawer = {
            associateID: "",
            reason: "",
          };
        });
    } else {
      this.toast.showError("Please fill both the required fields");
    }
  }

  submitCashRegister() {
    this.registerService.submitDrawer(this.registerId).then((res) => {
      //SHOW NOTIFICATION
      this.submitDialog = false;
      this.openRecon = false;
      this.toast.handleResponse(res);
      this.loadList();
    });
  }

  openSearchForm() {
    this.searchDialog = true;

    this.registerService.getBranchBasic().then((data) => {
      this.myStores = data.branches_list;
    });
  }

  closePINDialog(params) {
    this.associateID = params[0];
    this.toast.showSuccess("Welcome " + params[1] + " " + params[2]);
  }
}
</script>

<style scoped>
.cash-register tr th,
td {
  width: 40vw;
  padding: 2px;
  text-align: center;
}

.p-inputtext {
  padding: 0.2rem 0.75rem;
}

.register-total {
  background-color: #004c97;
  color: #fff;
}
</style>
