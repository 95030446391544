
import { Options, Vue } from "vue-class-component";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import CheckinService from "../../service/CheckinService";
import ItemConditions from "../../components/ItemConditions.vue";
import ServiceQty from "../../components/ServiceQty.vue";
import ItemQty from "../../components/ItemQty.vue";
import Coupons from "../../components/Coupons.vue";
import DueDate from "../../components/DueDate.vue";
import PauseReceipt from "../../components/PauseReceipt.vue";
import ReferralorCorp from "../../components/ReferralorCorp.vue";
import PaymentScreen from "../../components/PaymentScreen.vue";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { useStore } from "../../store";
import router from "../../router";
import AutoComplete from "primevue/autocomplete";
import CustomerDialog from "../../components/CustomerDialog.vue";

import {
  ServiceList,
  DepartList,
  ItemList,
  ColorList,
  TypeList,
  UpchargeList,
  ConditionList,
  ConditionType,
  ModifiedConditionType,
  ReceiptServices,
  ServiceItem,
  ReceiptType,
  CustomerSearchFields,
  PriceList,
  WeekDates
}

from "../checkIn/ICheckin";
import { camelCase } from "lodash";

@Options({
  components: {
    TabView,
    TabPanel,
    ItemConditions,
    ServiceQty,
    ItemQty,
    DueDate,
    Coupons,
    PauseReceipt,
    AutoComplete,
    ReferralorCorp,
    AssociatePinVerification,
    PaymentScreen,
    CustomerDialog
  },
  // emits: ["emitItemDetailEvent"],
})
export default class CheckIn extends Vue {

  private dialogTitle = "Customer Payment Method";
  private verificationType = "";
  private toast;
  private checkTxn;
  private paymentDialog = false;
  private statusDialog = false;
  private selectedPriceList: PriceList = {
    id: "",
    scheduleName: "",
  };
  private referralorCorpDialog = false;
  private fixedExpressToday = 0;
  private pauseReceiptDialog = false;
  private imagePathService = "";
  private imagePathDepartment = "";
  private conditionsList: ConditionType[] = [];
  private couponList = [];
  private weekDatesList: WeekDates [] = [];
  private customerHistory = null;
  private modifiedConditionList: ModifiedConditionType[] = [];
  private receiptServices: ReceiptServices[] = [];
  private checkedTags: ColorList[] = [];
  private checkedCrease = "";
  private checkedFolding = "";
  private checkedUpcharges: UpchargeList[] = [];
  private customerReceipt: ReceiptType = {
    id: "",
    date: this.getReceiptDate,
    timeEntrance: "",
    dueDate: "",
    dueTime: "",
    canadaExempt: "",
    provinceExempt: "",
    exempt1: "",
    exempt2: "",
    discount: 0,
    discountMethod: "",
    discountName: "",
    discountId: "",
    description: "",
    cusId: "",
    customer: "",
    customerTel: "",
    customerEmail: "",
    taxCode: "",
    employeeName: "",
    branchCode: "",
    telephone: "",
    branchName: "",
    address: "",
    customerNeedles: 0,
    totalBill: 0,
    totalPaid: 0,
    totalExpress: 0,
    totalQty: 0,
    storeId: "",
    employeeId: "",
    priceListId: "",
    accountType: "",
    referalOrCorp: "",
    referalOrCorpId: "",
    corpEmp: "",
    corpPo: "",
    corpReq: "",
    corporateMeta: [],
    status: "",
    taxMethod: "",
    taxAmount: 0,
    taxName: "",
    paymentMethod: "",
    totalTendered: 0,
    totalChange: 0,
    invoiceServices: this.computedServices,
    invoicePayment: [],
  };

  private capacityLabels = [];
  private onTheRackLabels = [];
  private scheduleDefault = "";
  private customerList: CustomerSearchFields[] = [];
  private componentName = "";
  private selectedCustomer = "";
  private itemQty = 0;
  private expressAmount = 0;
  private price = 0;
  private itemIndex = 0;
  private serviceIndex = 0;
  private serviceDesc = "";
  private discountId = "";
  private discountMethod = "";
  private discountName = "";
  private discount = 0;
  private express = "";
  private serviceExpressDate = "";
  private serviceExpressTime = "";
  private serviceCouponID = "";
  private description = "";
  private dueDateDialog = false;
  private conditionDialog = false;
  private serviceDialog = false;
  private itemQtyDialog = false;
  private couponDialog = false;
  private store = useStore();
  private activeServiceObj = {
    id: "",
    name: "",
  };

  private activeDepartmentObj = {
    id: "",
    sector: "",
  };

  private activeItemObj = {
    id: "",
    itemId: "",
    itemName: "",
    price: 0,
    hasCondition: "",
  };

  private serviceList: ServiceList[] = [];

  private departList: DepartList[] = [];

  private itemList: ItemList[] = [];

  private colorList: ColorList[] = [];

  private typeList: TypeList[] = [];

  private upchargeList: UpchargeList[] = [];

  private conditionList: ConditionList[] = [];

  private creaseValue = ["None", "Yes", "No"];

  private foldingValue = ["None", "Hanger", "Folded"];

  private priceList: PriceList[] = [];

  private multiAxisOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: true,
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          ticks: {
            min: 0,
            max: 100,
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
  };

  private taxDetail = ["", 0, 0];
  private permission = "";
  private customerStatus = "";

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
    this.imagePathService = this.checkTxn.getBaseURL() + "uploads/services/";
    this.imagePathDepartment =
      this.checkTxn.getBaseURL() + "uploads/departments/";
  }

  mounted() {
    this.verificationType = "associate";
    this.getServices();
  }

  openCustomerDialog() {
    this.dialogTitle = "Add Customer";
    this.permission = "yes";
    this.customerStatus = "openCustomer";
  }

  //USED TO UPDATE THE PIN STATUS
  updateCustomerStatus() {
    this.customerStatus = "";
  }

  searchCustomer(event) {
    // setTimeout(() => {
    this.checkTxn.searchCustomer(event.query.trim()).then((data) => {
      const d = this.camelizeKeys(data);
      const searchedResult: CustomerSearchFields[] = [];
      this.customerList = [];

      d.forEach((e) => {
        let mood = "gray";

        const dMood = e.fields.customerReviewsCustomerMood;

        if (Array.isArray(dMood) && dMood) {
          mood = dMood.reverse()[0];
        } else {
          if (dMood == "green") {
            mood = "green";
          } else if (dMood == "red") {
            mood = "red";
          } else {
            mood = "gray";
          }
        }

        const cus: CustomerSearchFields = {
          contact: e.fields.contact,
          firstName: e.fields.firstName,
          lastName: e.fields.lastName,
          email: e.fields.email,
          customerMood: mood,
          customerId: e.fields.customerId,
        };

        searchedResult.push(cus);
      });

      this.customerList = searchedResult;
    });
    //}, 500);
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  getServices() {
    this.checkTxn.getSetUp(this.customerReceipt.priceListId).then((data) => {
      if (data.alert == "danger") {
        this.toast.handleResponse(data);
        this.redirectHome();
      } else {
        this.priceList = this.camelizeKeys(data.schedule_list);
        this.serviceList = this.camelizeKeys(data.servicelist);
        this.colorList = this.camelizeKeys(data.colorlist);
        this.conditionList = this.camelizeKeys(data.condlist);
        this.upchargeList = this.camelizeKeys(data.upchargelist);
        this.typeList = this.camelizeKeys(data.typelist);
        this.scheduleDefault = this.camelizeKeys(data.schedule_default);
        this.customerReceipt.priceListId = this.camelizeKeys(
          data.schedule_default
        );
        this.setPriceList(data.schedule_default);

        this.taxDetail = this.camelizeKeys(data.tax_array);

        this.setWeekDates(data);

        this.capacityLabels  = data.capacity[0];
        this.onTheRackLabels = data.capacity[1];

       // console.log(this.capacityLabels);
       // console.log(this.onTheRackLabels);
      }
    });
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  getDepartment(service) {
    if (this.customerReceipt.cusId != "") {
      this.activeServiceObj.id = service.id;
      this.activeServiceObj.name = service.name;
      this.resetTags();

      const receiptService: ReceiptServices = {
        id: "",
        invId: "",
        date: "",
        serviceId: service.id,
        serviceName: service.name,
        discountId: "",
        discount: 0,
        discountMethod: "",
        discountName: "",
        qty: 1,
        tax: 0,
        description: "",
        tag1: "",
        tag2: "",
        dueDate: "",
        dueTime: "",
        upchargeCharges: 0,
        upchargeChargesPer: 0,
        express: "",
        expressAmt: 0,
        expressNotification: "",
        totalBill: 0,
        checkStatus: "",
        workingStatus: "",
        checkRedo: "",
        createdEmpId: "",
        storeId: "",
        completedTime: "",
        completedDate: "",
        startTime: "",
        startDate: "",
        itemList: [],
        workingEmployee: "",
      };

      this.receiptServices.push(receiptService);
      this.activeDepartmentObj.id = "";
      this.activeDepartmentObj.sector = "";

      this.itemList = [];

      this.checkTxn
        .getDepartments(
          this.activeServiceObj.id,
          this.customerReceipt.priceListId
        )
        .then((data) => {
          this.departList = this.camelizeKeys(data);
        });
    } else {
      this.toast.showWarning("Please select the customer first");
    }
  }

  get computedServices() {
    return this.receiptServices;
  }

  setUpCharges(upcharge) {
    let checkExists = false;

    this.checkedUpcharges.filter((e, i) => {
      if (e.id == upcharge.id) {
        this.checkedUpcharges.splice(i, 1);
        checkExists = true;
      }
    });

    if (checkExists == false) {
      this.checkedUpcharges.push(upcharge);
    }

    this.prepareTag2();
  }

  prepareTag2() {
    let tag2 = "";
    let symbol = "";

    if (this.checkedCrease != "None") {
      tag2 = " Crease : " + this.checkedCrease + " / ";
    }

    if (this.checkedFolding != "None") {
      tag2 = tag2 + " Folding : " + this.checkedFolding + " / ";
    }

    this.checkedUpcharges.forEach((e) => {
      if (e.optChargeValue == "percentage") {
        symbol = "%";
      } else {
        symbol = "$";
      }

      tag2 =
        tag2 + " " + e.priceName + " " + e.chargeValue + " " + symbol + " / ";
    });

    if (this.receiptServices.length > 0) {
      this.receiptServices[0].tag2 = tag2;
    }
  }

  resetTags() {
    this.checkedCrease = "None";
    this.checkedFolding = "None";
    this.checkedTags = [];
    this.checkedUpcharges = [];
  }

  getItems(depart) {
    this.activeDepartmentObj.id = depart.id;
    this.activeDepartmentObj.sector = depart.sector;

    this.checkTxn
      .getItems(
        this.activeServiceObj.id,
        depart.id,
        this.customerReceipt.priceListId
      )
      .then((data) => {
        this.itemList = this.camelizeKeys(data);
      });
  }

  getItemConditions(item) {
    this.activeItemObj = item;
    this.modifiedConditionList = [];

    let serviceLen = 0;

    if (this.receiptServices.length > 0) {
      serviceLen = this.receiptServices.length - 1;
    }

    //LAST SERVICE ITEMS LENGTH
    const itemListLength = this.receiptServices[serviceLen].itemList.length;

    const serviceItem: ServiceItem = {
      salesItemId: itemListLength,
      itemId: item.itemId,
      itemName: item.itemName,
      itemQty: 1,
      price: this.formatAmount(item.price),
      tag3: "",
      itemDetailCharges: 0,
    };

    this.receiptServices[serviceLen].itemList.push(serviceItem);
    this.receiptServices[serviceLen].itemList;

    if (this.activeItemObj.hasCondition == "Yes") {
      this.conditionDialog = true;
      this.checkTxn
        .getItemConditions(
          this.activeItemObj.itemId,
          this.customerReceipt.priceListId
        )
        .then((data) => {
          this.conditionsList = this.camelizeKeys(data);
          this.conditionsList.forEach((e) => {
            this.modifiedConditionList.push({
              conditionStatus: e.conditionStatus,
              condittionPrice: e.condittionPrice,
              itemId: e.itemId,
              itemSpId: e.itemSpId,
              itemSpName: e.itemSpName,
              parentItemId: e.parentItemId,
              sectionName: e.sectionName,
              quantity: 1,
              totalAmount: e.condittionPrice,
              checkedItem: false,
            });
          });
        });
    } else {
      this.conditionDialog = false;
    }

    this.calculateTotals();
  }

  adjustServiceQty(service, serviceIndex) {
    this.checkTxn.getServicesCoupon(service.serviceId).then((data) => {
      const d = this.camelizeKeys(data);
      this.couponList = d.serviceCoupons;
      this.fixedExpressToday = Number(d.expressAmount);
    });

    this.serviceDialog = true;
    this.serviceIndex = serviceIndex;
    this.itemQty = service.qty;
    this.serviceDesc = service.description;
    this.serviceExpressDate = service.dueDate;
    this.serviceExpressTime = service.dueTime;
    this.discountId = service.discountId;
    this.express = service.express;
  }

  adjustItemQty(itemDetail, itemIndex, serviceIndex) {
    this.itemIndex = itemIndex;
    this.serviceIndex = serviceIndex;
    this.itemQty = itemDetail.itemQty;
    this.price = itemDetail.price;
    this.itemQtyDialog = true;
  }

  openDueDate() {
    if (this.customerReceipt.cusId != "") {
      this.dueDateDialog = true;
    } else {
      this.toast.showWarning("Please select the customer first");
    }
  }

  closeItemPopup(params) {
    let tag3 = "";
    let charges = 0;

    let serviceIndex = 0;
    let itemIndex = 0;

    if (this.receiptServices.length > 0) {
      serviceIndex = this.receiptServices.length - 1;
    }

    if (this.receiptServices[serviceIndex].itemList.length > 0) {
      itemIndex = this.receiptServices[serviceIndex].itemList.length - 1;
    }

    params.forEach((e) => {
      if (e.checkedItem == true) {
        const amt = this.formatAmount(e.totalAmount);
        charges = charges + Number(amt);
        tag3 =
          tag3 + "(" + e.quantity + "x) " + e.itemSpName + " $" + amt + " | ";
      }
    });

    if (charges > 0) {
      this.receiptServices[serviceIndex].itemList[
        itemIndex
      ].itemDetailCharges = charges;
    }

    if (tag3 != "") {
      this.receiptServices[serviceIndex].itemList[itemIndex].tag3 = tag3;
    }

    this.calculateTotals();

    this.conditionDialog = false;
  }

  closeServicePopup(params) {
    const reversedIdx = this.receiptServices.length - 1 - this.serviceIndex;

    if (params[0] == "Done") {
      this.receiptServices[reversedIdx].express = params[5];

      if (params[5] == "express") {
        this.toast.showSuccess("Service marked as EXPRESS");

        this.receiptServices[reversedIdx].dueDate = params[4];
        this.receiptServices[reversedIdx].dueTime = params[3];
      } else {
        this.receiptServices[reversedIdx].dueDate = "";
        this.receiptServices[reversedIdx].dueTime = "";
      }

      this.receiptServices[reversedIdx].description = params[2];
      this.receiptServices[reversedIdx].expressAmt = 15;
      this.receiptServices[reversedIdx].discountId = params[6];
      this.receiptServices[reversedIdx].discountMethod = params[8];
      this.receiptServices[reversedIdx].discountName = params[7];
      this.receiptServices[reversedIdx].discount = Number(params[9]);
      this.receiptServices[reversedIdx].qty = Number(params[1]);

      this.toast.showSuccess("Service adjustment done successfully");

      this.calculateTotals();
    } else if (params[0] == "Cancel") {
      this.toast.showSuccess("Service adjustment cancelled successfully");
    }

    this.serviceDialog = false;
  }

  closeItemQtyDialog(params) {
    const reversedIdx = this.receiptServices.length - 1 - this.serviceIndex;

    if (params[0] == "Done") {
      this.receiptServices[reversedIdx].itemList[this.itemIndex].itemQty =
        params[1];
      this.receiptServices[reversedIdx].itemList[this.itemIndex].price =
        params[2];

      this.toast.showSuccess("Item adjustment done successfully");

      this.calculateTotals();
    } else if (params[0] == "Cancel") {
      this.toast.showSuccess("Item adjustment cancelled successfully");
    } else if (params[0] == "Delete") {
      this.receiptServices[reversedIdx].itemList.splice(this.itemIndex, 1);

      this.toast.showSuccess("Item deleted successfully");
      this.calculateTotals();
    }

    this.itemQtyDialog = false;
  }

  closeCouponDialog(params) {
    if (params.length <= 0) {
      this.toast.showInfo("No coupon is applied");
    } else {
      this.customerReceipt.discountMethod = params[0].discountMethod;
      this.customerReceipt.discountId = params[0].discountMethod;
      this.customerReceipt.discount = Number(params[0].discount);
      this.customerReceipt.discountName = params[0].discountName;

      this.toast.showSuccess(
        params[0].discountName + " coupon is applied successfully"
      );

      this.calculateTotals();
    }

    this.couponDialog = false;
  }

  closePauseDialog(params) {
    if (params[0] == "paused") {
      this.clearReceipt();
    } else if (params[0] == "resume") {
      const data = params[1];
      this.receiptServices = data.invoiceServices;
      this.customerReceipt = data;
    }

    this.pauseReceiptDialog = false;
  }

  closeDueDateDialog(params) {
    if (params[2] == "closeDialog") {
      this.toast.showInfo("Due Date & Time is not saved");
    } else {
      this.customerReceipt.dueDate = params[0];
      this.customerReceipt.dueTime = params[1];
      this.toast.showSuccess("Due Date & Time saved Successfully");
    }

    this.dueDateDialog = false;
  }

  parseString(value) {
    return String(value) + "x";
  }

  deleteService(index) {
    const reversedIdx = this.receiptServices.length - 1 - index;

    this.receiptServices.splice(reversedIdx, 1);

    this.activeDepartmentObj.id = "";
    this.activeDepartmentObj.sector = "";

    this.activeServiceObj.id = "";
    this.activeServiceObj.name = "";

    this.toast.showSuccess("Service deleted successfully");

    this.calculateTotals();
  }

  get totalServices() {
    return this.receiptServices.length;
  }

  redirectHome() {
    router.push({ path: "/store/dashboard", params: {} });
  }

  redirectTransaction() {
    router.push({ path: "/process/transactions", params: {} });
  }

  openCouponDailog() {
    this.couponDialog = true;
    this.couponList = [];
    this.checkTxn.getCoupons().then((data) => {
      this.couponList = this.camelizeKeys(data);
    });
  }

  clearReceipt() {
    this.receiptServices = [];

    this.customerReceipt.id = "";
    this.customerReceipt.timeEntrance = "";
    this.customerReceipt.dueDate = "";
    this.customerReceipt.dueTime = "";
    this.customerReceipt.canadaExempt = "";
    this.customerReceipt.provinceExempt = "";
    this.customerReceipt.exempt1 = "";
    this.customerReceipt.exempt2 = "";
    this.customerReceipt.discount = 0;
    this.customerReceipt.discountMethod = "";
    this.customerReceipt.discountName = "";
    this.customerReceipt.discountId = "";
    this.customerReceipt.description = "";
    this.customerReceipt.cusId = "";
    this.customerReceipt.customer = "";
    this.customerReceipt.customerTel = "";
    this.customerReceipt.customerEmail = "";
    this.customerReceipt.taxCode = "";
    this.customerReceipt.branchCode = "";
    this.customerReceipt.telephone = "";
    this.customerReceipt.branchName = "";
    this.customerReceipt.address = "";
    this.customerReceipt.customerNeedles = 0;
    this.customerReceipt.totalBill = 0;
    this.customerReceipt.totalPaid = 0;
    this.customerReceipt.totalExpress = 0;
    this.customerReceipt.totalQty = 0;
    this.customerReceipt.storeId = "";
    this.customerReceipt.priceListId = this.scheduleDefault;
    this.customerReceipt.accountType = "";
    this.customerReceipt.referalOrCorp = "";
    this.customerReceipt.referalOrCorpId = "";
    this.customerReceipt.corpEmp = "";
    this.customerReceipt.corpPo = "";
    this.customerReceipt.corpReq = "";
    this.customerReceipt.corporateMeta = [];
    this.customerReceipt.status = "";
    this.customerReceipt.taxMethod = "";
    this.customerReceipt.taxAmount = 0;
    this.customerReceipt.taxName = "";
    this.customerReceipt.paymentMethod = "";
    this.customerReceipt.totalTendered = 0;
    this.customerReceipt.totalChange = 0;
    this.customerReceipt.invoiceServices = this.computedServices;
    this.customerReceipt.invoicePayment = [];

    this.activeDepartmentObj.id = "";
    this.activeDepartmentObj.sector = "";

    this.activeItemObj.id = "";
    this.activeItemObj.itemId = "";
    this.activeItemObj.itemName = "";
    this.activeItemObj.price = 0;
    this.activeItemObj.hasCondition = "";

    this.selectedCustomer = "";

    this.statusDialog = false;

    this.toast.showSuccess("Receipt cleared successfully");
  }

  openPauseResumeReceipt() {
    this.pauseReceiptDialog = true;
  }

  get getReceiptDate() {
    return moment().format("YYYY-MM-DD");
  }

  saveCustomer(event) {
    const custInfo = event.value;
    this.selectedCustomer = custInfo.firstName + " " + custInfo.lastName;
    this.customerReceipt.customer =
      custInfo.firstName + " " + custInfo.lastName;
    this.customerReceipt.cusId = custInfo.customerId;
    this.customerReceipt.customerEmail = custInfo.email;
    this.customerReceipt.customerTel = custInfo.contact;

    this.openReferalOrCorp();
  }

  openReferalOrCorp() {
    //referralOrCorp
    this.checkTxn.referralOrCorp(this.customerReceipt.cusId).then((data) => {
      this.customerHistory = this.camelizeKeys(data);
    });

    this.referralorCorpDialog = true;
  }

  closeRefOrCorpDialog(params) {
    this.referralorCorpDialog = false;

    if (params[1] == "closeDialog") {
      this.selectedCustomer = "";
      this.customerReceipt.cusId = "";
      this.customerReceipt.customerEmail = "";
      this.customerReceipt.customerTel = "";

      this.customerReceipt.customerNeedles = 0;
      this.customerReceipt.accountType = "";
      this.customerReceipt.referalOrCorp = "";
      this.customerReceipt.referalOrCorpId = "";
      this.customerReceipt.corpEmp = "";
      this.customerReceipt.corpPo = "";
      this.customerReceipt.corpReq = "";
      this.customerReceipt.corporateMeta = [];
      this.customerReceipt.exempt1 = "";
      this.customerReceipt.exempt2 = "";
      this.customerReceipt.canadaExempt = "";
      this.customerReceipt.provinceExempt = "";

      this.toast.showInfo("Please select any customer to continue process");
    } else if (params[1] == "next") {
      const res = params[0];

      const customerTaxDetail = params[2];

      this.customerReceipt.customerNeedles = res.Neddle;
      this.customerReceipt.referalOrCorp = res.selectedAction;
      this.customerReceipt.referalOrCorpId = res.referralorCorpID;
      this.customerReceipt.accountType = res.accountType;
      this.customerReceipt.corpEmp = res.empData;
      this.customerReceipt.corpPo = res.poData;
      this.customerReceipt.corpReq = res.reqData;
      this.customerReceipt.corporateMeta = res.corporateMeta;
      this.customerReceipt.exempt1 = customerTaxDetail.exempt1;
      this.customerReceipt.exempt2 = customerTaxDetail.exempt2;
      this.customerReceipt.canadaExempt = customerTaxDetail.hstExempt;
      this.customerReceipt.provinceExempt = customerTaxDetail.pstExempt;

      if (
        res.selectedAction == "Referral" ||
        res.selectedAction == "corporate"
      ) {
        this.toast.showInfo("Receipt Tag is for " + res.selectedAction);
      }

      if (
        res.priceListId != "" &&
        res.selectedAction == "corporate" &&
        this.customerReceipt.priceListId != res.priceListId
      ) {
        this.customerReceipt.priceListId = res.priceListId;
        this.setPriceList(res.priceListId);
        this.getServices();
        this.toast.showInfo(
          "Price List is changed and locked for corporate. Price List ID assigned for " +
            res.referralorCorpName +
            " is " +
            res.priceListId
        );
      }

      this.toast.showSuccess(
        "Step completed successfully. Now you can add  services"
      );
    }
  }

  processPriceList(event) {
    if (this.customerReceipt.priceListId != event.value.id) {
      this.customerReceipt.priceListId = event.value.id;
      this.getServices();
      this.toast.showInfo(
        "Price list is changed to " + event.value.scheduleName
      );
    }
  }

  setPriceList(priceListID) {
    const scheduleObj = this.priceList.filter((e) => {
      if (priceListID == e.id) {
        return e;
      }
    });

    if (scheduleObj.length > 0) {
      const schedule = {
        id: scheduleObj[0].id,
        scheduleName: scheduleObj[0].scheduleName,
      };

      this.selectedPriceList = schedule;
    }
  }

  get subtotals() {
    let totalService = 0;

    this.customerReceipt.invoiceServices.forEach((e) => {
      totalService = totalService + e.totalBill;
    });

    return totalService;
  }

  calculateTotals() {
    this.customerReceipt.totalBill = 0;

    this.customerReceipt.invoiceServices.forEach((e) => {
      e.totalBill = 0;

      e.itemList.forEach((item) => {
        const itemTotal =
          item.itemQty * item.price + Number(item.itemDetailCharges);
        e.totalBill = e.totalBill + Number(itemTotal * e.qty);
      });

      const totalService = e.totalBill;

      if (e.express == "express" && e.expressAmt != 0) {
        const expressAmt = (e.totalBill / 100) * Number(e.expressAmt);
        e.totalBill = e.totalBill + expressAmt;
      }

      //UPCHARGES
      e.totalBill = e.totalBill + e.upchargeCharges * e.qty;

      //UPCHARGES PERCENTAGE
      const perAmount = (totalService / 100) * e.upchargeChargesPer;

      e.totalBill = e.totalBill + perAmount;

      if (e.discount != 0) {
        const discAmt = (e.totalBill / 100) * Number(e.discount);
        e.totalBill = e.totalBill - discAmt;
      }
      this.customerReceipt.totalBill =
        this.customerReceipt.totalBill + e.totalBill;
    });

    const subtotal = this.customerReceipt.totalBill;

    const taxAmount = (subtotal / 100) * this.getSummerizedTax;

    this.customerReceipt.totalBill = this.customerReceipt.totalBill + taxAmount;

    if (this.customerReceipt.discount != 0) {
      let couponAmount = 0;

      if (this.customerReceipt.discountMethod == "%") {
        couponAmount = (subtotal / 100) * this.customerReceipt.discount;
      } else {
        couponAmount = this.customerReceipt.discount;
      }

      this.customerReceipt.totalBill = this.formatAmount(
        this.customerReceipt.totalBill - couponAmount
      );
    }
  }

  closePINDialog(params) {
    this.customerReceipt.employeeName = params[1] + " " + params[2];
    this.customerReceipt.employeeId = params[0];
    this.toast.showSuccess("Welcome " + this.customerReceipt.employeeName);
  }

  setServiceTags() {
    const index = this.receiptServices.length - 1 - this.serviceIndex;

    let tags = "";

    this.checkedTags.forEach((e) => {
      tags = tags + e.categoryName + " / ";
    });

    this.receiptServices[index].tag1 = tags;

    tags = "";

    if (this.checkedCrease != "None") {
      tags = tags + "Crease : " + this.checkedCrease + " / ";
    }

    if (this.checkedFolding != "None") {
      tags = tags + "Folding : " + this.checkedFolding + " / ";
    }

    let chargesPercentage = 0;
    let chargesAmount = 0;

    this.checkedUpcharges.forEach((e) => {
      let symbol = "";

      if (e.optChargeValue == "percentage") {
        chargesPercentage = chargesPercentage + Number(e.chargeValue);
        symbol = "%";
      } else {
        chargesAmount = chargesAmount + Number(e.chargeValue);
        symbol = "$";
      }

      tags = tags + e.priceName + " " + e.chargeValue + " " + symbol + " / ";
    });

    this.receiptServices[index].upchargeCharges = chargesAmount;
    this.receiptServices[index].upchargeChargesPer = chargesPercentage;
    this.receiptServices[index].tag2 = tags;

    this.calculateTotals();
  }

  get getSummerizedTax() {
    let totalTax = 0;

    if (this.customerReceipt.canadaExempt == "no") {
      totalTax = totalTax + Number(this.taxDetail[1]);
    }

    if (this.customerReceipt.provinceExempt == "no") {
      totalTax = totalTax + Number(this.taxDetail[2]);
    }

    return totalTax;
  }

  openPaymentMethod() {
    if (
      this.customerReceipt.dueDate != "" &&
      this.customerReceipt.dueTime != ""
    ) {
      this.paymentDialog = true;
    } else {
      this.toast.showInfo("Please set due date and time");
    }
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  getProceededPayments(paymentList) {
    const totalPaid = this.getTotalPaid(paymentList);
    const method = this.getPaymentMwthod(paymentList);

    this.customerReceipt.paymentMethod = method;
    this.customerReceipt.totalPaid = totalPaid[0];
    this.customerReceipt.totalTendered = totalPaid[1];
    this.customerReceipt.totalChange = totalPaid[2];
    this.customerReceipt.totalQty = this.totalServices;
    this.customerReceipt.totalExpress = this.totalExpressAmount;
    this.customerReceipt.taxCode =
      this.taxDetail[0] + " " + this.getSummerizedTax + " %";
    this.customerReceipt.taxName = String(this.taxDetail[0]);
    this.customerReceipt.taxMethod = "%";
    this.customerReceipt.taxAmount = this.getSummerizedTax;

    this.customerReceipt.invoicePayment = paymentList;

    this.checkTxn.sendCheckInRq(this.customerReceipt).then((res) => {
      if (res.alert == "info") {
        this.paymentDialog = false;
        this.clearReceipt();
      }

      this.toast.handleResponse(res);
    });
  }

  getPaymentMwthod(paymnetList) {
    let method = "";

    if (paymnetList.length == 0) {
      method = "Pay Later";
    } else if (paymnetList.length == 1) {
      method = paymnetList[0].paymentType;
    } else if (paymnetList.length > 1) {
      method = "Split";
    }

    return method;
  }

  getTotalPaid(paymnetList) {
    let totalPaid = 0;
    let totalTendered = 0;
    let totalChange = 0;

    paymnetList.forEach((e) => {
      totalPaid = totalPaid + Number(e.transTotalAmount);
      totalTendered = totalTendered + Number(e.tendered);
      totalChange = totalChange + Number(e.change);
    });

    return [totalPaid, totalTendered, totalChange];
  }

  get totalExpressAmount() {
    let totalExpress = 0;

    this.receiptServices.forEach((e) => {
      if (e.express == "express") {
        totalExpress = totalExpress + Number(e.totalBill);
      }
    });

    return totalExpress;
  }

  setWeekDates(dates)
  {

    const d = this.camelizeKeys(dates.week_dates);

     this.weekDatesList  = [
      {
        dayName: 'Sunday',
        dayDate: d.sunday,
      },
      {
        dayName: 'Monday',
        dayDate: d.monday,
      },
      {
        dayName: 'Tuesday',
        dayDate: d.tuesday,
      },
      {
        dayName: 'Wednesday',
        dayDate: d.wednesday,
      },
      {
        dayName: 'Thursday',
        dayDate: d.thursday,
      },
      {
        dayName: 'Friday',
        dayDate: d.friday,
      },
      {
        dayName: 'Saturday',
        dayDate: d.saturday,
      },
      {
        dayName: 'Next Sunday',
        dayDate: d.nextSunday,
      },
      {
        dayName: 'Next Monday',
        dayDate: d.nextMonday,
      },
      {
        dayName: 'Next Tuesday',
        dayDate: d.nextTuesday,
      },
      {
        dayName: 'Next Wednesday',
        dayDate: d.nextWednesday,
      },
      {
        dayName: 'Next Thursday',
        dayDate: d.nextThursday,
      },
      {
        dayName: 'Next Friday',
        dayDate: d.nextFriday,
      },
      {
        dayName: 'Next Saturday',
        dayDate: d.nextSaturday,
      }
    ];
  }

  get multiAxisData()  {

     return {
      labels: ["09-10AM", "10-11AM", "11-12PM", "12-01PM", "01-02PM", "02-03PM", "03-04PM", "04-05PM","05-06PM","06-07PM","07-08PM","08-09PM","09-10PM"],
      datasets: [
        {
          label: "Dataset 1",
          backgroundColor: "#EC407A",
          yAxisID: "y-axis-1",
          data: this.onTheRackLabels,
        },
        {
          label: "Dataset 2",
          backgroundColor: "#78909C",
          yAxisID: "y-axis-2",
          data: this.capacityLabels,
        },
      ],
    };
  }

}
