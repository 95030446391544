<template>
  <div style="height: 0.2em; background-color: #eee" class="p-p-0">
    <ProgressBar
      v-if="progressBar"
      mode="indeterminate"
      style="height: 0.2em"
    />
  </div>
  <div class="row p-p-0 p-m-0">
    <div class="col-md-4 section1 p-pl-1 p-pr-1">
      <div class="top-details">
        Associate : {{ customerReceipt.employeeName }}
        <span class="pull-right">
          Date: {{ formatDate(customerReceipt.date) }}
        </span>
      </div>
      <div class="row">
        <div class="col-md-10">
          <span class="p-fluid">
            <AutoComplete
              autoFocus
              :delay="1000"
              :minLength="3"
              @item-select="saveCustomer($event)"
              scrollHeight="400"
              v-model="selectedCustomer"
              :suggestions="customerList"
              placeholder="Search Customer"
              @complete="searchCustomer($event)"
              :dropdown="false"
            >
              <template #item="slotProps">
                <div>
                  Cus Name :
                  <b class="pull-right">
                    {{ slotProps.item.firstName.toUpperCase() }}
                    {{ slotProps.item.lastName.toUpperCase() }}
                  </b>
                </div>
                <div>
                  Email :
                  <span class="pull-right"> {{ slotProps.item.email }} </span>
                </div>
                <div>
                  Contact :
                  <span class="pull-right"> {{ slotProps.item.contact }} </span>
                </div>
                <div
                  :class="{
                    moodRed: slotProps.item.customerMood == 'red',
                    moodGreen: slotProps.item.customerMood == 'green',
                    moodGray: slotProps.item.customerMood == 'gray',
                  }"
                  class="p-p-1"
                ></div>
              </template>
            </AutoComplete>
          </span>
          <p class="p-m-0" v-if="customerReceipt.cusId != ''">
            <a class="p-p-0"><small>View Profile</small></a>
          </p>
        </div>
        <div class="col-md-2">
          <Button
            class="p-button-success"
            icon="pi pi-plus-circle"
            @click="openCustomerDialog"
          />
        </div>
      </div>
      <div class="invoice-height p-mt-1">
        <table class="table p-m-0 table-borderless">
          <thead>
            <tr class="hightlist-total">
              <th class="">UNIT</th>
              <th class="">DESCRIPTION</th>
              <th class="">PRICE</th>
              <th class="">TOTAL</th>
              <th class=""></th>
            </tr>
          </thead>
          <tbody class="tbody-inv-cls">
            <template
              v-for="(
                services, index
              ) in customerReceipt.invoiceServices.slice().reverse()"
              :key="services"
            >
              <tr
                class="service_row_cls"
                :class="{ expressReceipt: services.express == 'express' }"
              >
                <td>
                  <Button
                    :label="parseString(services.qty)"
                    @click="adjustServiceQty(services, index)"
                    class="p-button-success p-pl-2 p-pr-2 p-pt-0 p-pb-0"
                  />
                </td>
                <td>{{ services.serviceName }}</td>
                <td></td>
                <td>$ {{ formatAmount(services.totalBill) }}</td>
                <td @click="deleteService(index)" class="delete-service">
                  <i class="pi pi-times"></i>
                </td>
              </tr>

              <template
                v-for="(item, itemIndex) in services.itemList"
                :key="item"
              >
                <tr :class="{ expressReceipt: services.express == 'express' }">
                  <td></td>
                  <td style="width: 200px">
                    <i
                      style="color: #004c97"
                      class="pi pi-arrow-circle-right"
                    ></i>
                    {{ item.itemName }}
                  </td>
                  <td v-if="item.price != ''">
                    <Button
                      :label="parseString(item.itemQty)"
                      @click="adjustItemQty(item, itemIndex, index)"
                      class="p-button-primary p-pl-2 p-pr-2 p-pt-0 p-pb-0"
                    />
                    ${{ formatAmount(item.price) }}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr
                  v-if="item.tag3 != ''"
                  :class="{ expressReceipt: services.express == 'express' }"
                >
                  <td></td>
                  <td class="tag-font" colspan="4">
                    <i class="pi pi-tag" aria-hidden="true"></i>
                    <i>{{ item.tag3 }}</i>
                  </td>
                </tr>
              </template>
              <tr
                v-if="services.express == 'express'"
                :class="{ expressReceipt: services.express == 'express' }"
              >
                <td colspan="5">
                  <i class="pi pi-tag" aria-hidden="true"></i>
                  <span> Express 15%</span>
                  <i
                    class="pull-right"
                    v-if="services.dueDate != '' && services.dueTime != ''"
                  >
                    Due {{ formatDate(services.dueDate) }}
                    {{ formatTime(services.dueTime) }}
                  </i>
                </td>
              </tr>
              <tr
                v-if="services.discountId != ''"
                :class="{ expressReceipt: services.express == 'express' }"
              >
                <td colspan="5">
                  <i class="pi pi-tag" aria-hidden="true"></i>
                  <i>
                    {{ services.discountName }} {{ services.discount }}
                    {{ services.discountMethod }}
                  </i>
                </td>
              </tr>
              <tr
                v-if="services.tag1 != ''"
                :class="{ expressReceipt: services.express == 'express' }"
              >
                <td colspan="5" class="tag-font">
                  <i class="pi pi-tag" aria-hidden="true"></i>
                  {{ services.tag1 }}
                </td>
              </tr>
              <tr
                v-if="services.tag2 != ''"
                :class="{ expressReceipt: services.express == 'express' }"
              >
                <td colspan="5" class="tag-font">
                  <i class="pi pi-tag" aria-hidden="true"></i>
                  {{ services.tag2 }}
                </td>
              </tr>
              <tr
                v-if="services.description != ''"
                :class="{ expressReceipt: services.express == 'express' }"
              >
                <td colspan="5">
                  <i class="pi pi-tag" aria-hidden="true"></i>
                  <i> {{ services.description }}</i>
                </td>
              </tr>
            </template>
            <tr class="inv-btm-line">
              <td class="p-0 m-0" colspan="5"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <table class="table inv-left-bottom p-m-0">
        <tr>
          <td>
            <b>Memo</b>
          </td>
          <td class="text-right">
            <InputText
              v-model="customerReceipt.description"
              class="p-p-0"
              :style="{ width: '20vw' }"
            />
          </td>
        </tr>
        <tr>
          <td>
            <b>Due </b>
          </td>
          <td>
            <span class="pull-right due-color">
              {{ formatDate(customerReceipt.dueDate) }}
              {{ formatTime(customerReceipt.dueTime) }}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <b>Sub Total</b>
          </td>
          <td>
            <span class="pull-right">${{ formatAmount(subtotals) }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <b>Coupon </b>
          </td>
          <td>
            <span v-if="customerReceipt.discountId != ''" class="pull-right"
              >{{ customerReceipt.discountName }}
              {{ customerReceipt.discount }}
              {{ customerReceipt.discountMethod }}</span
            >
          </td>
        </tr>
        <tr>
          <td>
            <b class="text-left"> {{ taxDetail[0] }} </b>
          </td>
          <td>
            <span class="pull-right">{{ getSummerizedTax }}%</span>
          </td>
        </tr>
      </table>
      <table class="table p-m-0">
        <tr>
          <td class="text-left p-p-1">
            <h5 class="p-m-0">
              <b>Pts : ${{ formatAmount(customerReceipt.customerNeedles) }} </b>
            </h5>
          </td>
          <td class="text-right p-p-1">
            <h5 class="p-m-0">
              <b> Items : {{ totalServices }} </b>
            </h5>
          </td>
          <td class="text-right p-p-1">
            <h5 class="p-m-0">
              <b>
                Total : $
                <span>{{ formatAmount(customerReceipt.totalBill) }}</span>
              </b>
            </h5>
          </td>
        </tr>
      </table>
      <table class="table p-m-0">
        <tr>
          <td class="text-center m-0 p-0">
            <Button
              label="COUPON"
              @click="openCouponDailog"
              class="p-button-primary p-button-lg col-md-12 check-bottom-button"
            />
          </td>
          <td class="text-center m-0 p-0">
            <Button
              label="PAUSE/RESUME"
              class="p-button-warning p-button-lg col-md-12 check-bottom-button"
              @click="openPauseResumeReceipt"
            />
          </td>
          <td class="text-center m-0 p-0">
            <Button
              label="CANCEL"
              class="p-button-danger p-button-lg col-md-12 check-bottom-button"
              @click="statusDialog = true"
            />
          </td>
        </tr>
      </table>
    </div>
    <div class="col-md-5 p-p-0">
      <div class="section2">
        <div class="flex-box-1">
          <div class="horizontal-row col-md-12 p-p-0">
            <span style="font-size: 10px" class="check_in_heading">
              SERVICES
            </span>
            <template v-if="serviceList.length > 0">
              <div
                class="text-center"
                v-for="service in serviceList"
                :key="service"
                :class="service.id == activeServiceObj.id ? 'active-item' : ''"
                @click="getDepartment(service)"
              >
                <img
                  class="order-screen-service"
                  :src="imagePathService + service.picture"
                />
                <span class="check_in_span">{{ service.name }}</span>
              </div>
            </template>
          </div>
        </div>

        <div class="flex-box-2">
          <div class="horizontal-row col-md-12 p-p-0">
            <span
              style="font-size: 9px; letter-spacing: -1px"
              class="check_in_heading"
            >
              DEPARTMENT
            </span>
            <template v-if="departList.length > 0">
              <div
                class="text-center"
                v-for="depart in departList"
                :key="depart"
                @click="getItems(depart)"
                :class="
                  depart.id == activeDepartmentObj.id ? 'active-item' : ''
                "
              >
                <img
                  class="order-screen-service"
                  :src="imagePathDepartment + depart.picture"
                />
                <span class="check_in_span">{{ depart.sector }}</span>
              </div>
            </template>
          </div>
        </div>
        <div class="flex-box-3">
          <span
            style="font-size: 10px; height: 42vh"
            class="item-list-sidearea check_in_heading p-m-0 p-p-0"
          >
            <i class="pi pi-star-o"></i>
            <i class="pi pi-star-o"></i>
            <i class="pi pi-star-o"></i>
            ITEM LIST
            <i class="pi pi-star-o"></i>
            <i class="pi pi-star-o"></i>
            <i class="pi pi-star-o"></i>
          </span>
          <div class="item-list-box" v-if="itemList.length > 0">
            <div class="row p-m-0">
              <template v-for="item in itemList" :key="item">
                <div
                  :class="item.id == activeItemObj.id ? 'active-item' : ''"
                  @click="getItemConditions(item)"
                  class="st-list-item col-md-6 col-sm-12 p-p-0"
                >
                  <label style="word-wrap: break-word">
                    {{ item.itemName }}
                  </label>
                  <br />
                  ${{ formatAmount(item.price) }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="flex-box-4">
          <div class="sec-tabs">
            <TabView>
              <TabPanel header="Color">
                <template v-for="color in colorList" :key="color">
                  <label
                    :style="'background-color:' + color.categoryName"
                    class="colorinput"
                  >
                    <input
                      name="colorCheckBox"
                      type="checkbox"
                      v-model="checkedTags"
                      :value="color"
                      @change="setServiceTags()"
                    />
                  </label>
                </template>
              </TabPanel>
              <TabPanel header="Type">
                <template v-for="type in typeList" :key="type">
                  <label class="typeLabel">
                    <input
                      name="typeCheckBox"
                      type="checkbox"
                      v-model="checkedTags"
                      :value="type"
                      @change="setServiceTags()"
                    />
                    {{ type.categoryName }}
                  </label>
                </template>
              </TabPanel>
              <TabPanel header="Stain & Damage">
                <template v-for="cond in conditionList" :key="cond">
                  <label class="condLabel">
                    <input
                      name="condCheckBox"
                      type="checkbox"
                      v-model="checkedTags"
                      :value="cond"
                      @change="setServiceTags()"
                    />
                    {{ cond.categoryName }}
                  </label>
                </template>
              </TabPanel>
              <TabPanel header="Option">
                <div class="row">
                  <b class="p-mr-2"> Crease : </b>
                  <template v-for="crease in creaseValue" :key="crease">
                    <label class="mr-4">
                      {{ crease }}
                      <input
                        type="radio"
                        v-model="checkedCrease"
                        class="ml-2"
                        :value="crease"
                        @change="setServiceTags()"
                      />
                    </label>
                  </template>
                </div>
                <div class="row">
                  <b class="p-mr-2"> Folding : </b>
                  <template v-for="folding in foldingValue" :key="folding">
                    <label class="mr-4">
                      {{ folding }}
                      <input
                        type="radio"
                        v-model="checkedFolding"
                        class="ml-2"
                        :value="folding"
                        @change="setServiceTags()"
                      />
                    </label>
                  </template>
                </div>
              </TabPanel>
              <TabPanel header="UpCharge">
                <template v-for="upcharge in upchargeList" :key="upcharge">
                  <label class="condLabel">
                    <input
                      name="condCheckBox"
                      type="checkbox"
                      v-model="checkedUpcharges"
                      :value="upcharge"
                      @change="setServiceTags()"
                    />
                    {{ upcharge.priceName }} {{ upcharge.chargeValue }}
                    <span v-if="upcharge.optChargeValue == 'percentage'">
                      %
                    </span>
                    <span v-if="upcharge.optChargeValue == 'price'"> $ </span>
                  </label>
                </template>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 p-p-0">
      <div class="section3 p-ml-1 p-mr-1">
        <div class="sec-3-flex-1">
          <span class="p-buttonset">
            <Button
              label="Dashboard"
              class="col-md-6 black-btn p-button-lg"
              icon="pi pi-home"
              @click="redirectHome()"
            />
            <Button
              label="Transaction"
              class="col-md-6 p-button-primary p-button-lg"
              icon="pi pi-external-link"
              @click="redirectTransaction()"
            />
          </span>
          <Dropdown
            :options="priceList"
            v-model="selectedPriceList"
            :filter="true"
            optionLabel="scheduleName"
            placeholder="Search List"
            :disabled="customerReceipt.cusId != '' ? true : false"
            @change="processPriceList($event)"
          />
          <Button
            label="SET DATE & TIME"
            class="col-md-12 p-button-primary p-button-lg p-mt-1"
            icon="pi pi-calendar"
            @click="openDueDate()"
          />
          <Button
            label="PAYMENT"
            class="col-md-12 p-button-success p-button-lg p-mt-1"
            icon="pi pi-money-bill"
            @click="openPaymentMethod()"
          />
        </div>
        <div class="sec-3-flex-2">
          <Chart
            type="bar"
            :data="multiAxisData"
            :options="multiAxisOptions"
            :height="350"
          />
        </div>
        <div class="sec-3-flex-3">
          <div class="hightlist-total text-center">
            Total On Rack This Week $
          </div>
          <select class="form-control">
            <template v-for="d in weekDatesList" :key="d">
              <option :value="d.dayDate" >{{d.dayName}} {{formatDate(d.dayDate)}}</option>
            </template>
          </select>
        </div>
      </div>
    </div>
  </div>

  <ItemConditions
    :receiptDetail="{
      dialogStatus: conditionDialog,
      itemLists: modifiedConditionList,
    }"
    v-on:emitItemDetailEvent="closeItemPopup"
  />

  <ServiceQty
    :receiptDetail="{
      discountId: discountId,
      couponList: couponList,
      serviceDialog: serviceDialog,
      serviceExpressDate: serviceExpressDate,
      serviceExpressTime: serviceExpressTime,
      serviceDesc: serviceDesc,
      itemQty: itemQty,
      express: express,
      expressAmount: fixedExpressToday,
    }"
    v-on:emitServiceDetailEvent="closeServicePopup"
  />

  <ItemQty
    :receiptDetail="{
      itemQtyDialog: itemQtyDialog,
      itemPrice: price,
      itemQty: itemQty,
    }"
    v-on:emitItemQtyEvent="closeItemQtyDialog"
  />

  <DueDate
    :receiptDetail="{
      dueDateDialog: dueDateDialog,
    }"
    v-on:emitdueDateEvent="closeDueDateDialog"
  />

  <Coupons
    :receiptDetail="{
      couponDialog: couponDialog,
      couponList: couponList,
    }"
    v-on:emitCouponEvent="closeCouponDialog"
  />

  <PauseReceipt
    :receiptDetail="{
      pauseReceiptDialog: pauseReceiptDialog,
      customerReceipt: customerReceipt,
    }"
    v-on:emitPauseEvent="closePauseDialog"
  />
  <ReferralorCorp
    :receiptDetail="{
      referralorCorpDialog: referralorCorpDialog,
      customerHistory: customerHistory,
    }"
    v-on:emitreferralorCorpEvent="closeRefOrCorpDialog"
  />

  <AssociatePinVerification
    :receiptDetail="{ associateType: verificationType }"
    v-on:emitAssociatePinEvent="closePINDialog"
  />

  <PaymentScreen
    :receiptDetail="{
      dialogStatus: paymentDialog,
      totalBill: customerReceipt.totalBill,
      itemSource: 'Checkin',
      restriction: 'No',
      dialogTilte: dialogTitle,
      customerID: customerReceipt.cusId,
      customerName: customerReceipt.customer,
      needlePoints: customerReceipt.customerNeedles,
      closeConfirmation: true,
    }"
    v-on:closePaymentScreenEvent="closePaymentScreen"
    v-on:getProceededPaymentsEvent="getProceededPayments"
  />
  <CustomerDialog
    :customerDetail="{
      status: customerStatus,
      permission: permission,
      title: dialogTitle,
    }"
    v-on:updateCustomerStatus="updateCustomerStatus"
  />

  <Dialog
    v-model:visible="statusDialog"
    :style="{ width: '450px' }"
    header="Confirm"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>Are you sure to clear receipt ? </span>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="statusDialog = false"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-danger"
        @click="clearReceipt()"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import CheckinService from "../../service/CheckinService";
import ItemConditions from "../../components/ItemConditions.vue";
import ServiceQty from "../../components/ServiceQty.vue";
import ItemQty from "../../components/ItemQty.vue";
import Coupons from "../../components/Coupons.vue";
import DueDate from "../../components/DueDate.vue";
import PauseReceipt from "../../components/PauseReceipt.vue";
import ReferralorCorp from "../../components/ReferralorCorp.vue";
import PaymentScreen from "../../components/PaymentScreen.vue";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { useStore } from "../../store";
import router from "../../router";
import AutoComplete from "primevue/autocomplete";
import CustomerDialog from "../../components/CustomerDialog.vue";

import {
  ServiceList,
  DepartList,
  ItemList,
  ColorList,
  TypeList,
  UpchargeList,
  ConditionList,
  ConditionType,
  ModifiedConditionType,
  ReceiptServices,
  ServiceItem,
  ReceiptType,
  CustomerSearchFields,
  PriceList,
  WeekDates
}

from "../checkIn/ICheckin";
import { camelCase } from "lodash";

@Options({
  components: {
    TabView,
    TabPanel,
    ItemConditions,
    ServiceQty,
    ItemQty,
    DueDate,
    Coupons,
    PauseReceipt,
    AutoComplete,
    ReferralorCorp,
    AssociatePinVerification,
    PaymentScreen,
    CustomerDialog
  },
  // emits: ["emitItemDetailEvent"],
})
export default class CheckIn extends Vue {

  private dialogTitle = "Customer Payment Method";
  private verificationType = "";
  private toast;
  private checkTxn;
  private paymentDialog = false;
  private statusDialog = false;
  private selectedPriceList: PriceList = {
    id: "",
    scheduleName: "",
  };
  private referralorCorpDialog = false;
  private fixedExpressToday = 0;
  private pauseReceiptDialog = false;
  private imagePathService = "";
  private imagePathDepartment = "";
  private conditionsList: ConditionType[] = [];
  private couponList = [];
  private weekDatesList: WeekDates [] = [];
  private customerHistory = null;
  private modifiedConditionList: ModifiedConditionType[] = [];
  private receiptServices: ReceiptServices[] = [];
  private checkedTags: ColorList[] = [];
  private checkedCrease = "";
  private checkedFolding = "";
  private checkedUpcharges: UpchargeList[] = [];
  private customerReceipt: ReceiptType = {
    id: "",
    date: this.getReceiptDate,
    timeEntrance: "",
    dueDate: "",
    dueTime: "",
    canadaExempt: "",
    provinceExempt: "",
    exempt1: "",
    exempt2: "",
    discount: 0,
    discountMethod: "",
    discountName: "",
    discountId: "",
    description: "",
    cusId: "",
    customer: "",
    customerTel: "",
    customerEmail: "",
    taxCode: "",
    employeeName: "",
    branchCode: "",
    telephone: "",
    branchName: "",
    address: "",
    customerNeedles: 0,
    totalBill: 0,
    totalPaid: 0,
    totalExpress: 0,
    totalQty: 0,
    storeId: "",
    employeeId: "",
    priceListId: "",
    accountType: "",
    referalOrCorp: "",
    referalOrCorpId: "",
    corpEmp: "",
    corpPo: "",
    corpReq: "",
    corporateMeta: [],
    status: "",
    taxMethod: "",
    taxAmount: 0,
    taxName: "",
    paymentMethod: "",
    totalTendered: 0,
    totalChange: 0,
    invoiceServices: this.computedServices,
    invoicePayment: [],
  };

  private capacityLabels = [];
  private onTheRackLabels = [];
  private scheduleDefault = "";
  private customerList: CustomerSearchFields[] = [];
  private componentName = "";
  private selectedCustomer = "";
  private itemQty = 0;
  private expressAmount = 0;
  private price = 0;
  private itemIndex = 0;
  private serviceIndex = 0;
  private serviceDesc = "";
  private discountId = "";
  private discountMethod = "";
  private discountName = "";
  private discount = 0;
  private express = "";
  private serviceExpressDate = "";
  private serviceExpressTime = "";
  private serviceCouponID = "";
  private description = "";
  private dueDateDialog = false;
  private conditionDialog = false;
  private serviceDialog = false;
  private itemQtyDialog = false;
  private couponDialog = false;
  private store = useStore();
  private activeServiceObj = {
    id: "",
    name: "",
  };

  private activeDepartmentObj = {
    id: "",
    sector: "",
  };

  private activeItemObj = {
    id: "",
    itemId: "",
    itemName: "",
    price: 0,
    hasCondition: "",
  };

  private serviceList: ServiceList[] = [];

  private departList: DepartList[] = [];

  private itemList: ItemList[] = [];

  private colorList: ColorList[] = [];

  private typeList: TypeList[] = [];

  private upchargeList: UpchargeList[] = [];

  private conditionList: ConditionList[] = [];

  private creaseValue = ["None", "Yes", "No"];

  private foldingValue = ["None", "Hanger", "Folded"];

  private priceList: PriceList[] = [];

  private multiAxisOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: true,
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          ticks: {
            min: 0,
            max: 100,
          },
        },
        {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-2",
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
  };

  private taxDetail = ["", 0, 0];
  private permission = "";
  private customerStatus = "";

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
    this.imagePathService = this.checkTxn.getBaseURL() + "uploads/services/";
    this.imagePathDepartment =
      this.checkTxn.getBaseURL() + "uploads/departments/";
  }

  mounted() {
    this.verificationType = "associate";
    this.getServices();
  }

  openCustomerDialog() {
    this.dialogTitle = "Add Customer";
    this.permission = "yes";
    this.customerStatus = "openCustomer";
  }

  //USED TO UPDATE THE PIN STATUS
  updateCustomerStatus() {
    this.customerStatus = "";
  }

  searchCustomer(event) {
    // setTimeout(() => {
    this.checkTxn.searchCustomer(event.query.trim()).then((data) => {
      const d = this.camelizeKeys(data);
      const searchedResult: CustomerSearchFields[] = [];
      this.customerList = [];

      d.forEach((e) => {
        let mood = "gray";

        const dMood = e.fields.customerReviewsCustomerMood;

        if (Array.isArray(dMood) && dMood) {
          mood = dMood.reverse()[0];
        } else {
          if (dMood == "green") {
            mood = "green";
          } else if (dMood == "red") {
            mood = "red";
          } else {
            mood = "gray";
          }
        }

        const cus: CustomerSearchFields = {
          contact: e.fields.contact,
          firstName: e.fields.firstName,
          lastName: e.fields.lastName,
          email: e.fields.email,
          customerMood: mood,
          customerId: e.fields.customerId,
        };

        searchedResult.push(cus);
      });

      this.customerList = searchedResult;
    });
    //}, 500);
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  getServices() {
    this.checkTxn.getSetUp(this.customerReceipt.priceListId).then((data) => {
      if (data.alert == "danger") {
        this.toast.handleResponse(data);
        this.redirectHome();
      } else {
        this.priceList = this.camelizeKeys(data.schedule_list);
        this.serviceList = this.camelizeKeys(data.servicelist);
        this.colorList = this.camelizeKeys(data.colorlist);
        this.conditionList = this.camelizeKeys(data.condlist);
        this.upchargeList = this.camelizeKeys(data.upchargelist);
        this.typeList = this.camelizeKeys(data.typelist);
        this.scheduleDefault = this.camelizeKeys(data.schedule_default);
        this.customerReceipt.priceListId = this.camelizeKeys(
          data.schedule_default
        );
        this.setPriceList(data.schedule_default);

        this.taxDetail = this.camelizeKeys(data.tax_array);

        this.setWeekDates(data);

        this.capacityLabels  = data.capacity[0];
        this.onTheRackLabels = data.capacity[1];

       // console.log(this.capacityLabels);
       // console.log(this.onTheRackLabels);
      }
    });
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }

  formatAmount(value) {
    value = Number(value);

    let balance = 0;

    if (value != "") {
      balance = value.toFixed(2);
    }
    return balance;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  getDepartment(service) {
    if (this.customerReceipt.cusId != "") {
      this.activeServiceObj.id = service.id;
      this.activeServiceObj.name = service.name;
      this.resetTags();

      const receiptService: ReceiptServices = {
        id: "",
        invId: "",
        date: "",
        serviceId: service.id,
        serviceName: service.name,
        discountId: "",
        discount: 0,
        discountMethod: "",
        discountName: "",
        qty: 1,
        tax: 0,
        description: "",
        tag1: "",
        tag2: "",
        dueDate: "",
        dueTime: "",
        upchargeCharges: 0,
        upchargeChargesPer: 0,
        express: "",
        expressAmt: 0,
        expressNotification: "",
        totalBill: 0,
        checkStatus: "",
        workingStatus: "",
        checkRedo: "",
        createdEmpId: "",
        storeId: "",
        completedTime: "",
        completedDate: "",
        startTime: "",
        startDate: "",
        itemList: [],
        workingEmployee: "",
      };

      this.receiptServices.push(receiptService);
      this.activeDepartmentObj.id = "";
      this.activeDepartmentObj.sector = "";

      this.itemList = [];

      this.checkTxn
        .getDepartments(
          this.activeServiceObj.id,
          this.customerReceipt.priceListId
        )
        .then((data) => {
          this.departList = this.camelizeKeys(data);
        });
    } else {
      this.toast.showWarning("Please select the customer first");
    }
  }

  get computedServices() {
    return this.receiptServices;
  }

  setUpCharges(upcharge) {
    let checkExists = false;

    this.checkedUpcharges.filter((e, i) => {
      if (e.id == upcharge.id) {
        this.checkedUpcharges.splice(i, 1);
        checkExists = true;
      }
    });

    if (checkExists == false) {
      this.checkedUpcharges.push(upcharge);
    }

    this.prepareTag2();
  }

  prepareTag2() {
    let tag2 = "";
    let symbol = "";

    if (this.checkedCrease != "None") {
      tag2 = " Crease : " + this.checkedCrease + " / ";
    }

    if (this.checkedFolding != "None") {
      tag2 = tag2 + " Folding : " + this.checkedFolding + " / ";
    }

    this.checkedUpcharges.forEach((e) => {
      if (e.optChargeValue == "percentage") {
        symbol = "%";
      } else {
        symbol = "$";
      }

      tag2 =
        tag2 + " " + e.priceName + " " + e.chargeValue + " " + symbol + " / ";
    });

    if (this.receiptServices.length > 0) {
      this.receiptServices[0].tag2 = tag2;
    }
  }

  resetTags() {
    this.checkedCrease = "None";
    this.checkedFolding = "None";
    this.checkedTags = [];
    this.checkedUpcharges = [];
  }

  getItems(depart) {
    this.activeDepartmentObj.id = depart.id;
    this.activeDepartmentObj.sector = depart.sector;

    this.checkTxn
      .getItems(
        this.activeServiceObj.id,
        depart.id,
        this.customerReceipt.priceListId
      )
      .then((data) => {
        this.itemList = this.camelizeKeys(data);
      });
  }

  getItemConditions(item) {
    this.activeItemObj = item;
    this.modifiedConditionList = [];

    let serviceLen = 0;

    if (this.receiptServices.length > 0) {
      serviceLen = this.receiptServices.length - 1;
    }

    //LAST SERVICE ITEMS LENGTH
    const itemListLength = this.receiptServices[serviceLen].itemList.length;

    const serviceItem: ServiceItem = {
      salesItemId: itemListLength,
      itemId: item.itemId,
      itemName: item.itemName,
      itemQty: 1,
      price: this.formatAmount(item.price),
      tag3: "",
      itemDetailCharges: 0,
    };

    this.receiptServices[serviceLen].itemList.push(serviceItem);
    this.receiptServices[serviceLen].itemList;

    if (this.activeItemObj.hasCondition == "Yes") {
      this.conditionDialog = true;
      this.checkTxn
        .getItemConditions(
          this.activeItemObj.itemId,
          this.customerReceipt.priceListId
        )
        .then((data) => {
          this.conditionsList = this.camelizeKeys(data);
          this.conditionsList.forEach((e) => {
            this.modifiedConditionList.push({
              conditionStatus: e.conditionStatus,
              condittionPrice: e.condittionPrice,
              itemId: e.itemId,
              itemSpId: e.itemSpId,
              itemSpName: e.itemSpName,
              parentItemId: e.parentItemId,
              sectionName: e.sectionName,
              quantity: 1,
              totalAmount: e.condittionPrice,
              checkedItem: false,
            });
          });
        });
    } else {
      this.conditionDialog = false;
    }

    this.calculateTotals();
  }

  adjustServiceQty(service, serviceIndex) {
    this.checkTxn.getServicesCoupon(service.serviceId).then((data) => {
      const d = this.camelizeKeys(data);
      this.couponList = d.serviceCoupons;
      this.fixedExpressToday = Number(d.expressAmount);
    });

    this.serviceDialog = true;
    this.serviceIndex = serviceIndex;
    this.itemQty = service.qty;
    this.serviceDesc = service.description;
    this.serviceExpressDate = service.dueDate;
    this.serviceExpressTime = service.dueTime;
    this.discountId = service.discountId;
    this.express = service.express;
  }

  adjustItemQty(itemDetail, itemIndex, serviceIndex) {
    this.itemIndex = itemIndex;
    this.serviceIndex = serviceIndex;
    this.itemQty = itemDetail.itemQty;
    this.price = itemDetail.price;
    this.itemQtyDialog = true;
  }

  openDueDate() {
    if (this.customerReceipt.cusId != "") {
      this.dueDateDialog = true;
    } else {
      this.toast.showWarning("Please select the customer first");
    }
  }

  closeItemPopup(params) {
    let tag3 = "";
    let charges = 0;

    let serviceIndex = 0;
    let itemIndex = 0;

    if (this.receiptServices.length > 0) {
      serviceIndex = this.receiptServices.length - 1;
    }

    if (this.receiptServices[serviceIndex].itemList.length > 0) {
      itemIndex = this.receiptServices[serviceIndex].itemList.length - 1;
    }

    params.forEach((e) => {
      if (e.checkedItem == true) {
        const amt = this.formatAmount(e.totalAmount);
        charges = charges + Number(amt);
        tag3 =
          tag3 + "(" + e.quantity + "x) " + e.itemSpName + " $" + amt + " | ";
      }
    });

    if (charges > 0) {
      this.receiptServices[serviceIndex].itemList[
        itemIndex
      ].itemDetailCharges = charges;
    }

    if (tag3 != "") {
      this.receiptServices[serviceIndex].itemList[itemIndex].tag3 = tag3;
    }

    this.calculateTotals();

    this.conditionDialog = false;
  }

  closeServicePopup(params) {
    const reversedIdx = this.receiptServices.length - 1 - this.serviceIndex;

    if (params[0] == "Done") {
      this.receiptServices[reversedIdx].express = params[5];

      if (params[5] == "express") {
        this.toast.showSuccess("Service marked as EXPRESS");

        this.receiptServices[reversedIdx].dueDate = params[4];
        this.receiptServices[reversedIdx].dueTime = params[3];
      } else {
        this.receiptServices[reversedIdx].dueDate = "";
        this.receiptServices[reversedIdx].dueTime = "";
      }

      this.receiptServices[reversedIdx].description = params[2];
      this.receiptServices[reversedIdx].expressAmt = 15;
      this.receiptServices[reversedIdx].discountId = params[6];
      this.receiptServices[reversedIdx].discountMethod = params[8];
      this.receiptServices[reversedIdx].discountName = params[7];
      this.receiptServices[reversedIdx].discount = Number(params[9]);
      this.receiptServices[reversedIdx].qty = Number(params[1]);

      this.toast.showSuccess("Service adjustment done successfully");

      this.calculateTotals();
    } else if (params[0] == "Cancel") {
      this.toast.showSuccess("Service adjustment cancelled successfully");
    }

    this.serviceDialog = false;
  }

  closeItemQtyDialog(params) {
    const reversedIdx = this.receiptServices.length - 1 - this.serviceIndex;

    if (params[0] == "Done") {
      this.receiptServices[reversedIdx].itemList[this.itemIndex].itemQty =
        params[1];
      this.receiptServices[reversedIdx].itemList[this.itemIndex].price =
        params[2];

      this.toast.showSuccess("Item adjustment done successfully");

      this.calculateTotals();
    } else if (params[0] == "Cancel") {
      this.toast.showSuccess("Item adjustment cancelled successfully");
    } else if (params[0] == "Delete") {
      this.receiptServices[reversedIdx].itemList.splice(this.itemIndex, 1);

      this.toast.showSuccess("Item deleted successfully");
      this.calculateTotals();
    }

    this.itemQtyDialog = false;
  }

  closeCouponDialog(params) {
    if (params.length <= 0) {
      this.toast.showInfo("No coupon is applied");
    } else {
      this.customerReceipt.discountMethod = params[0].discountMethod;
      this.customerReceipt.discountId = params[0].discountMethod;
      this.customerReceipt.discount = Number(params[0].discount);
      this.customerReceipt.discountName = params[0].discountName;

      this.toast.showSuccess(
        params[0].discountName + " coupon is applied successfully"
      );

      this.calculateTotals();
    }

    this.couponDialog = false;
  }

  closePauseDialog(params) {
    if (params[0] == "paused") {
      this.clearReceipt();
    } else if (params[0] == "resume") {
      const data = params[1];
      this.receiptServices = data.invoiceServices;
      this.customerReceipt = data;
    }

    this.pauseReceiptDialog = false;
  }

  closeDueDateDialog(params) {
    if (params[2] == "closeDialog") {
      this.toast.showInfo("Due Date & Time is not saved");
    } else {
      this.customerReceipt.dueDate = params[0];
      this.customerReceipt.dueTime = params[1];
      this.toast.showSuccess("Due Date & Time saved Successfully");
    }

    this.dueDateDialog = false;
  }

  parseString(value) {
    return String(value) + "x";
  }

  deleteService(index) {
    const reversedIdx = this.receiptServices.length - 1 - index;

    this.receiptServices.splice(reversedIdx, 1);

    this.activeDepartmentObj.id = "";
    this.activeDepartmentObj.sector = "";

    this.activeServiceObj.id = "";
    this.activeServiceObj.name = "";

    this.toast.showSuccess("Service deleted successfully");

    this.calculateTotals();
  }

  get totalServices() {
    return this.receiptServices.length;
  }

  redirectHome() {
    router.push({ path: "/store/dashboard", params: {} });
  }

  redirectTransaction() {
    router.push({ path: "/process/transactions", params: {} });
  }

  openCouponDailog() {
    this.couponDialog = true;
    this.couponList = [];
    this.checkTxn.getCoupons().then((data) => {
      this.couponList = this.camelizeKeys(data);
    });
  }

  clearReceipt() {
    this.receiptServices = [];

    this.customerReceipt.id = "";
    this.customerReceipt.timeEntrance = "";
    this.customerReceipt.dueDate = "";
    this.customerReceipt.dueTime = "";
    this.customerReceipt.canadaExempt = "";
    this.customerReceipt.provinceExempt = "";
    this.customerReceipt.exempt1 = "";
    this.customerReceipt.exempt2 = "";
    this.customerReceipt.discount = 0;
    this.customerReceipt.discountMethod = "";
    this.customerReceipt.discountName = "";
    this.customerReceipt.discountId = "";
    this.customerReceipt.description = "";
    this.customerReceipt.cusId = "";
    this.customerReceipt.customer = "";
    this.customerReceipt.customerTel = "";
    this.customerReceipt.customerEmail = "";
    this.customerReceipt.taxCode = "";
    this.customerReceipt.branchCode = "";
    this.customerReceipt.telephone = "";
    this.customerReceipt.branchName = "";
    this.customerReceipt.address = "";
    this.customerReceipt.customerNeedles = 0;
    this.customerReceipt.totalBill = 0;
    this.customerReceipt.totalPaid = 0;
    this.customerReceipt.totalExpress = 0;
    this.customerReceipt.totalQty = 0;
    this.customerReceipt.storeId = "";
    this.customerReceipt.priceListId = this.scheduleDefault;
    this.customerReceipt.accountType = "";
    this.customerReceipt.referalOrCorp = "";
    this.customerReceipt.referalOrCorpId = "";
    this.customerReceipt.corpEmp = "";
    this.customerReceipt.corpPo = "";
    this.customerReceipt.corpReq = "";
    this.customerReceipt.corporateMeta = [];
    this.customerReceipt.status = "";
    this.customerReceipt.taxMethod = "";
    this.customerReceipt.taxAmount = 0;
    this.customerReceipt.taxName = "";
    this.customerReceipt.paymentMethod = "";
    this.customerReceipt.totalTendered = 0;
    this.customerReceipt.totalChange = 0;
    this.customerReceipt.invoiceServices = this.computedServices;
    this.customerReceipt.invoicePayment = [];

    this.activeDepartmentObj.id = "";
    this.activeDepartmentObj.sector = "";

    this.activeItemObj.id = "";
    this.activeItemObj.itemId = "";
    this.activeItemObj.itemName = "";
    this.activeItemObj.price = 0;
    this.activeItemObj.hasCondition = "";

    this.selectedCustomer = "";

    this.statusDialog = false;

    this.toast.showSuccess("Receipt cleared successfully");
  }

  openPauseResumeReceipt() {
    this.pauseReceiptDialog = true;
  }

  get getReceiptDate() {
    return moment().format("YYYY-MM-DD");
  }

  saveCustomer(event) {
    const custInfo = event.value;
    this.selectedCustomer = custInfo.firstName + " " + custInfo.lastName;
    this.customerReceipt.customer =
      custInfo.firstName + " " + custInfo.lastName;
    this.customerReceipt.cusId = custInfo.customerId;
    this.customerReceipt.customerEmail = custInfo.email;
    this.customerReceipt.customerTel = custInfo.contact;

    this.openReferalOrCorp();
  }

  openReferalOrCorp() {
    //referralOrCorp
    this.checkTxn.referralOrCorp(this.customerReceipt.cusId).then((data) => {
      this.customerHistory = this.camelizeKeys(data);
    });

    this.referralorCorpDialog = true;
  }

  closeRefOrCorpDialog(params) {
    this.referralorCorpDialog = false;

    if (params[1] == "closeDialog") {
      this.selectedCustomer = "";
      this.customerReceipt.cusId = "";
      this.customerReceipt.customerEmail = "";
      this.customerReceipt.customerTel = "";

      this.customerReceipt.customerNeedles = 0;
      this.customerReceipt.accountType = "";
      this.customerReceipt.referalOrCorp = "";
      this.customerReceipt.referalOrCorpId = "";
      this.customerReceipt.corpEmp = "";
      this.customerReceipt.corpPo = "";
      this.customerReceipt.corpReq = "";
      this.customerReceipt.corporateMeta = [];
      this.customerReceipt.exempt1 = "";
      this.customerReceipt.exempt2 = "";
      this.customerReceipt.canadaExempt = "";
      this.customerReceipt.provinceExempt = "";

      this.toast.showInfo("Please select any customer to continue process");
    } else if (params[1] == "next") {
      const res = params[0];

      const customerTaxDetail = params[2];

      this.customerReceipt.customerNeedles = res.Neddle;
      this.customerReceipt.referalOrCorp = res.selectedAction;
      this.customerReceipt.referalOrCorpId = res.referralorCorpID;
      this.customerReceipt.accountType = res.accountType;
      this.customerReceipt.corpEmp = res.empData;
      this.customerReceipt.corpPo = res.poData;
      this.customerReceipt.corpReq = res.reqData;
      this.customerReceipt.corporateMeta = res.corporateMeta;
      this.customerReceipt.exempt1 = customerTaxDetail.exempt1;
      this.customerReceipt.exempt2 = customerTaxDetail.exempt2;
      this.customerReceipt.canadaExempt = customerTaxDetail.hstExempt;
      this.customerReceipt.provinceExempt = customerTaxDetail.pstExempt;

      if (
        res.selectedAction == "Referral" ||
        res.selectedAction == "corporate"
      ) {
        this.toast.showInfo("Receipt Tag is for " + res.selectedAction);
      }

      if (
        res.priceListId != "" &&
        res.selectedAction == "corporate" &&
        this.customerReceipt.priceListId != res.priceListId
      ) {
        this.customerReceipt.priceListId = res.priceListId;
        this.setPriceList(res.priceListId);
        this.getServices();
        this.toast.showInfo(
          "Price List is changed and locked for corporate. Price List ID assigned for " +
            res.referralorCorpName +
            " is " +
            res.priceListId
        );
      }

      this.toast.showSuccess(
        "Step completed successfully. Now you can add  services"
      );
    }
  }

  processPriceList(event) {
    if (this.customerReceipt.priceListId != event.value.id) {
      this.customerReceipt.priceListId = event.value.id;
      this.getServices();
      this.toast.showInfo(
        "Price list is changed to " + event.value.scheduleName
      );
    }
  }

  setPriceList(priceListID) {
    const scheduleObj = this.priceList.filter((e) => {
      if (priceListID == e.id) {
        return e;
      }
    });

    if (scheduleObj.length > 0) {
      const schedule = {
        id: scheduleObj[0].id,
        scheduleName: scheduleObj[0].scheduleName,
      };

      this.selectedPriceList = schedule;
    }
  }

  get subtotals() {
    let totalService = 0;

    this.customerReceipt.invoiceServices.forEach((e) => {
      totalService = totalService + e.totalBill;
    });

    return totalService;
  }

  calculateTotals() {
    this.customerReceipt.totalBill = 0;

    this.customerReceipt.invoiceServices.forEach((e) => {
      e.totalBill = 0;

      e.itemList.forEach((item) => {
        const itemTotal =
          item.itemQty * item.price + Number(item.itemDetailCharges);
        e.totalBill = e.totalBill + Number(itemTotal * e.qty);
      });

      const totalService = e.totalBill;

      if (e.express == "express" && e.expressAmt != 0) {
        const expressAmt = (e.totalBill / 100) * Number(e.expressAmt);
        e.totalBill = e.totalBill + expressAmt;
      }

      //UPCHARGES
      e.totalBill = e.totalBill + e.upchargeCharges * e.qty;

      //UPCHARGES PERCENTAGE
      const perAmount = (totalService / 100) * e.upchargeChargesPer;

      e.totalBill = e.totalBill + perAmount;

      if (e.discount != 0) {
        const discAmt = (e.totalBill / 100) * Number(e.discount);
        e.totalBill = e.totalBill - discAmt;
      }
      this.customerReceipt.totalBill =
        this.customerReceipt.totalBill + e.totalBill;
    });

    const subtotal = this.customerReceipt.totalBill;

    const taxAmount = (subtotal / 100) * this.getSummerizedTax;

    this.customerReceipt.totalBill = this.customerReceipt.totalBill + taxAmount;

    if (this.customerReceipt.discount != 0) {
      let couponAmount = 0;

      if (this.customerReceipt.discountMethod == "%") {
        couponAmount = (subtotal / 100) * this.customerReceipt.discount;
      } else {
        couponAmount = this.customerReceipt.discount;
      }

      this.customerReceipt.totalBill = this.formatAmount(
        this.customerReceipt.totalBill - couponAmount
      );
    }
  }

  closePINDialog(params) {
    this.customerReceipt.employeeName = params[1] + " " + params[2];
    this.customerReceipt.employeeId = params[0];
    this.toast.showSuccess("Welcome " + this.customerReceipt.employeeName);
  }

  setServiceTags() {
    const index = this.receiptServices.length - 1 - this.serviceIndex;

    let tags = "";

    this.checkedTags.forEach((e) => {
      tags = tags + e.categoryName + " / ";
    });

    this.receiptServices[index].tag1 = tags;

    tags = "";

    if (this.checkedCrease != "None") {
      tags = tags + "Crease : " + this.checkedCrease + " / ";
    }

    if (this.checkedFolding != "None") {
      tags = tags + "Folding : " + this.checkedFolding + " / ";
    }

    let chargesPercentage = 0;
    let chargesAmount = 0;

    this.checkedUpcharges.forEach((e) => {
      let symbol = "";

      if (e.optChargeValue == "percentage") {
        chargesPercentage = chargesPercentage + Number(e.chargeValue);
        symbol = "%";
      } else {
        chargesAmount = chargesAmount + Number(e.chargeValue);
        symbol = "$";
      }

      tags = tags + e.priceName + " " + e.chargeValue + " " + symbol + " / ";
    });

    this.receiptServices[index].upchargeCharges = chargesAmount;
    this.receiptServices[index].upchargeChargesPer = chargesPercentage;
    this.receiptServices[index].tag2 = tags;

    this.calculateTotals();
  }

  get getSummerizedTax() {
    let totalTax = 0;

    if (this.customerReceipt.canadaExempt == "no") {
      totalTax = totalTax + Number(this.taxDetail[1]);
    }

    if (this.customerReceipt.provinceExempt == "no") {
      totalTax = totalTax + Number(this.taxDetail[2]);
    }

    return totalTax;
  }

  openPaymentMethod() {
    if (
      this.customerReceipt.dueDate != "" &&
      this.customerReceipt.dueTime != ""
    ) {
      this.paymentDialog = true;
    } else {
      this.toast.showInfo("Please set due date and time");
    }
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  getProceededPayments(paymentList) {
    const totalPaid = this.getTotalPaid(paymentList);
    const method = this.getPaymentMwthod(paymentList);

    this.customerReceipt.paymentMethod = method;
    this.customerReceipt.totalPaid = totalPaid[0];
    this.customerReceipt.totalTendered = totalPaid[1];
    this.customerReceipt.totalChange = totalPaid[2];
    this.customerReceipt.totalQty = this.totalServices;
    this.customerReceipt.totalExpress = this.totalExpressAmount;
    this.customerReceipt.taxCode =
      this.taxDetail[0] + " " + this.getSummerizedTax + " %";
    this.customerReceipt.taxName = String(this.taxDetail[0]);
    this.customerReceipt.taxMethod = "%";
    this.customerReceipt.taxAmount = this.getSummerizedTax;

    this.customerReceipt.invoicePayment = paymentList;

    this.checkTxn.sendCheckInRq(this.customerReceipt).then((res) => {
      if (res.alert == "info") {
        this.paymentDialog = false;
        this.clearReceipt();
      }

      this.toast.handleResponse(res);
    });
  }

  getPaymentMwthod(paymnetList) {
    let method = "";

    if (paymnetList.length == 0) {
      method = "Pay Later";
    } else if (paymnetList.length == 1) {
      method = paymnetList[0].paymentType;
    } else if (paymnetList.length > 1) {
      method = "Split";
    }

    return method;
  }

  getTotalPaid(paymnetList) {
    let totalPaid = 0;
    let totalTendered = 0;
    let totalChange = 0;

    paymnetList.forEach((e) => {
      totalPaid = totalPaid + Number(e.transTotalAmount);
      totalTendered = totalTendered + Number(e.tendered);
      totalChange = totalChange + Number(e.change);
    });

    return [totalPaid, totalTendered, totalChange];
  }

  get totalExpressAmount() {
    let totalExpress = 0;

    this.receiptServices.forEach((e) => {
      if (e.express == "express") {
        totalExpress = totalExpress + Number(e.totalBill);
      }
    });

    return totalExpress;
  }

  setWeekDates(dates)
  {

    const d = this.camelizeKeys(dates.week_dates);

     this.weekDatesList  = [
      {
        dayName: 'Sunday',
        dayDate: d.sunday,
      },
      {
        dayName: 'Monday',
        dayDate: d.monday,
      },
      {
        dayName: 'Tuesday',
        dayDate: d.tuesday,
      },
      {
        dayName: 'Wednesday',
        dayDate: d.wednesday,
      },
      {
        dayName: 'Thursday',
        dayDate: d.thursday,
      },
      {
        dayName: 'Friday',
        dayDate: d.friday,
      },
      {
        dayName: 'Saturday',
        dayDate: d.saturday,
      },
      {
        dayName: 'Next Sunday',
        dayDate: d.nextSunday,
      },
      {
        dayName: 'Next Monday',
        dayDate: d.nextMonday,
      },
      {
        dayName: 'Next Tuesday',
        dayDate: d.nextTuesday,
      },
      {
        dayName: 'Next Wednesday',
        dayDate: d.nextWednesday,
      },
      {
        dayName: 'Next Thursday',
        dayDate: d.nextThursday,
      },
      {
        dayName: 'Next Friday',
        dayDate: d.nextFriday,
      },
      {
        dayName: 'Next Saturday',
        dayDate: d.nextSaturday,
      }
    ];
  }

  get multiAxisData()  {

     return {
      labels: ["09-10AM", "10-11AM", "11-12PM", "12-01PM", "01-02PM", "02-03PM", "03-04PM", "04-05PM","05-06PM","06-07PM","07-08PM","08-09PM","09-10PM"],
      datasets: [
        {
          label: "Dataset 1",
          backgroundColor: "#EC407A",
          yAxisID: "y-axis-1",
          data: this.onTheRackLabels,
        },
        {
          label: "Dataset 2",
          backgroundColor: "#78909C",
          yAxisID: "y-axis-2",
          data: this.capacityLabels,
        },
      ],
    };
  }

}
</script>

<style scoped>
.section1 {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  height: 99.5vh;
}

.section2 {
  display: flex;
  height: 99.5vh;
  flex-direction: column;
}

.section3 {
  background-color: #f7f7f7;
  display: flex;
  height: 99.5vh;
  flex-direction: column;
}

.sec-3-flex-2 {
  flex-grow: 1;
}

.black-btn {
  background-color: #353434;
}

.p-dropdown {
  width: 100%;
  margin-top: 5px;
}

.hightlist-total {
  background-color: #004c97;
  color: #fff;
}

.hightlist-total th {
  padding: 5px;
}

.service_row_cls {
  background-color: #74e2e2;
}
.inv-left-bottom td {
  padding: 3px;
}

.check-bottom-button {
  border-radius: 0;
}

.tbody-inv-cls td {
  padding: 3px;
}

.delete-service {
  color: #004c97;
  font-size: 18px;
  font-weight: bold;
}
.delete-service:hover {
  cursor: pointer;
}

.invoice-height {
  background-color: #fff;
  flex-grow: 1;
  width: 100%;
  overflow-y: scroll;
}

.inv-btm-line {
  border-bottom: 1px dotted #000;
}

.check_in_heading {
  writing-mode: vertical-rl;
  display: inline;
  float: left;
  letter-spacing: -1px;
  font-weight: bold;
  background-color: #004c97;
  color: #fff;
  padding-top: 2px;
  padding-bottom: 5px;
  margin: 0px;
  text-orientation: upright;
}

.item-list-sidearea {
  letter-spacing: 2px;
}

.order-screen-service {
  width: 70px !important;
  height: 70px !important;
  display: block;
  border-radius: 3px;
  margin: 0 auto;
}

.horizontal-row {
  width: 100vw;
  height: 116px;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
}

.horizontal-row div {
  width: 22%;
  background-color: #f7f7f7;
  float: none;
  height: 100%;
  border-radius: 1px;
  margin: 2px;
  padding-top: 10px;
  display: inline-block;
}

.horizontal-row :hover {
  cursor: pointer;
  background-color: #004c97;
  border-radius: 1px;
  color: #fff;
}

.active-item {
  cursor: pointer;
  background-color: #004c97 !important;
  border-radius: 1px;
  color: #fff;
}

.st-list-item {
  float: left;
  background-color: #eee;
  text-align: center;
  font-size: 16px;
  border: 2px solid #fff;
}

.st-list-item:hover {
  cursor: pointer;
  display: inline;
  background-color: #004c97;
  color: #fff;
}

.sec-tabs {
  height: 22vh;
  overflow-y: scroll;
}

.flex-box-3 {
  flex-grow: 1;
  background-color: #f7f7f7;
}

.item-list-box {
  height: 42vh;
  min-height: 42vh;
  overflow-y: scroll;
}

.colorinput {
  padding: 5px 10px;
  border-radius: 5px;
  margin: 2px;
}

.typeLabel {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 4px;
  margin: 2px 2px;
}

.condLabel {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 4px;
  margin: 2px 2px;
}

.due-color {
  color: #dc3545;
  font-weight: bold;
}

.tag-font {
  font-size: 12px;
}

.tag-font i {
  color: #004c97;
}

.moodRed {
  background-color: #dc3545;
}

.moodGreen {
  background-color: green;
}

.moodGray {
  background-color: gray;
}

.top-details {
  margin-bottom: 5px;
  padding: 2px;
  color: #fff;
  width: 100%;
  background-color: #004c97;
}

.expressReceipt {
  background-color: #dc3545;
  color: #fff;
}

.tags-style {
  padding: 0px;
  width: 95%;
  font-size: 12px;
  font-weight: bold;
  border: none;
}
</style>
